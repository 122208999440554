"use strict";

import moment from "moment";
import { Parser } from "expr-eval";
import LocalStorage from "store";
import indexBy from "lodash.indexby";

import UserStore from "../../stores/UserStore";

import { nutrNoSortCmp, getDemographic, computeDefaultMealRx, factorEnvelope } from "../../utils/Nutrition";
import { roundForHumans } from "../../utils/Math";
import { getDietsFromTags } from "../../utils/Diets";
import { fetchDocumentsById } from "../../utils/Content";
import { fetchVirtualPlan } from "../../utils/Plans";
import { getParticipantsForProfileByMealType } from "../../utils/Meals";

import basicMealConditions from "../../tables/basic-conditions";
import allNutrients from "../../tables/nutrients";
import allRecommendations from "../../tables/recommendations";
import allConditions from "../../tables/conditions";
import { calculateNearestPortionSize, getPortionSizes } from "../../tables/portions";
import allTags from "../../tables/tags";
import _ from 'lodash';

const mealTypes = {
    Breakfast: { s: "breakfast", p: "breakfasts" },
    Lunch: { s: "lunch", p: "lunches" },
    Dinner: { s: "dinner", p: "dinners" },
    Snack: { s: "snack", p: "snacks" },
};

export function getExpirationInfo(active_until, expiration) {
    let expirationObj = {};

    if (active_until && expiration !== "unlimited") {
        const day_diff = moment(active_until).startOf("day").diff(moment().startOf("day"), "days");
        const expired = day_diff < 0;
        const expiring_soon = !expired && day_diff <= 14;
        expirationObj = { expired, expiring_soon, days: Math.abs(day_diff) };
    }

    return expirationObj;
}

export function computeBmi(weightKg, heightCm) {
    const heightMeters = heightCm / 100;

    if (!weightKg || !heightCm) {
        return false;
    }

    const bmi = weightKg / (heightMeters * heightMeters);

    // Round to tenths
    return Math.round(bmi * 10) / 10;
}

export function cmToFeetInches(cm) {
    if (!cm) {
        return false;
    }

    // Convert to inches first.
    let inches = cm * 0.393701,
        feet = Math.floor(inches / 12);

    if (feet) {
        inches -= feet * 12;
    }

    inches = Math.round(inches);

    // Special case rounding up to next foot
    if (inches == 12) {
        feet += 1;
        inches = 0;
    }

    return { feet, inches };
}

export function evaluateRangeFormulas(parser, range, variables) {
    let min, max;

    const functions = {
        clamp: (v, min, max) => {
            if (v < min) {
                return min;
            }

            if (v > max) {
                return max;
            }

            return v;
        },
        gte: (value, target, pass, fail) => {
            return value >= target ? pass : fail;
        },
        lte: (value, target, pass, fail) => {
            return value <= target ? pass : fail;
        },
    };

    if (range.min) {
        min = roundForHumans(
            parser.parse(range.min).evaluate({
                ...variables,
                ...functions,
                kcal: variables.kcal_min,
            }),
        );
    }

    if (range.max) {
        max = roundForHumans(
            parser.parse(range.max).evaluate({
                ...variables,
                ...functions,
                kcal: variables.kcal_max,
            }),
        );
    }

    return { min, max };
}

export function getPatientDemographic(patient, demographics) {
    let patientDemo = "default";

    // Get patient age, gender, trimester, lactation flag
    const age = roundForHumans(moment().diff(patient.birthdate, "years", true));

    Object.keys(demographics).forEach((key) => {
        const { min_age, max_age, gender, trimester, lactating } = demographics[key];

        if (typeof min_age === "number" && age < min_age) {
            return;
        }

        if (typeof max_age === "number" && age > max_age) {
            return;
        }

        if (typeof gender !== "undefined" && patient.gender !== gender) {
            return;
        }

        patientDemo = key;
    });

    return patientDemo;
}

export function computePrescriptionFromConditions(patient, conditions = []) {
    const conflicts = {};
    const demographic = getDemographic(patient);

    const dri = {
        ...allRecommendations["all-ages"],
        ...allRecommendations[demographic],
    };

    let { target_energy_kcal, weight_kg, preferences = {} } = patient;

    target_energy_kcal = parseFloat(target_energy_kcal);

    let prescriptions = {};

    // Any change to conditions triggers a re-calcuation of the prescription.
    // This overwrites formulaic values, ignoring any entry the user has already entered.
    // If there are conflicts that had been resolved, if the
    let kcal_min = Math.round((target_energy_kcal - 100) / 5) * 5,
        kcal_max = Math.round((target_energy_kcal + 100) / 5) * 5;

    // Safe initialize prescriptions array
    prescriptions["all-day"] = prescriptions["all-day"] || {};

    const parser = new Parser();

    const parametersUsed = [];

    // Make sure we have the same nutrients in ALL the RXs
    const rxNutrNos = { 208: true };

    conditions
        .map((name) => allConditions.find((c) => c.name === name))
        .filter((v) => v)
        .forEach((condition) => {
            const conditionDemographic = getPatientDemographic(patient, condition.demographics);
            const templates = condition.templates[conditionDemographic];

            // Add the parameters from this condition to the full list.
            if (condition.parameters) {
                condition.parameters.forEach((param) => {
                    if (!parametersUsed.includes(param)) {
                        parametersUsed.push(param);
                    }
                });
            }

            // Could not find template for this user. Skip it.
            if (!templates) {
                return;
            }

            Object.keys(templates).forEach((rxType) => {
                const rxTemplate = templates[rxType];

                prescriptions[rxType] = prescriptions[rxType] || { 208: {} };

                Object.keys(rxTemplate).forEach((nutrNo) => {
                    rxNutrNos[nutrNo] = true;

                    const parameters = {
                        kcal_min,
                        kcal_max,
                        weight_kg: patient.weight_kg || 62,
                        dri: dri[nutrNo] || 0,
                    };

                    const { min, max } = evaluateRangeFormulas(parser, rxTemplate[nutrNo], parameters);

                    if (
                        prescriptions[rxType][nutrNo] &&
                        prescriptions[rxType][nutrNo].condition &&
                        (prescriptions[rxType][nutrNo].min != min || prescriptions[rxType][nutrNo].max != max)
                    ) {
                        // Do the mins and maxs mismatch? Only then are we a conflict.
                        conflicts[rxType] = conflicts[rxType] || {};
                        conflicts[rxType][nutrNo] = conflicts[rxType][nutrNo] || [
                            {
                                condition: prescriptions[rxType][nutrNo].condition.name,
                                min: prescriptions[rxType][nutrNo].min,
                                max: prescriptions[rxType][nutrNo].max,
                            },
                        ];
                        conflicts[rxType][nutrNo].push({ condition: condition.name, min, max });

                        // We keep the original values in the prescriptions, its a conflict,
                        // but the higher priority prescription takes precidence.

                        return;
                    }

                    const range = (prescriptions[rxType][nutrNo] = prescriptions[rxType][nutrNo] || { condition });

                    if (
                        typeof min !== "undefined" &&
                        typeof range.min === "undefined" &&
                        (!range.resolve_min || (range.resolve_min && range.resolve_min !== condition.name))
                    ) {
                        range.min = min;
                    }

                    if (
                        typeof max !== "undefined" &&
                        typeof range.max === "undefined" &&
                        (!range.resolve_max || (range.resolve_max && range.resolve_max !== condition.name))
                    ) {
                        range.max = max;
                    }
                });
            });
        });

    // Cleanup - make sure all the nutrients are in all prescriptions, and remove the conditions temporary property
    Object.keys(prescriptions).forEach((rxType) => {
        Object.keys(rxNutrNos).forEach((nutrNo) => {
            prescriptions[rxType][nutrNo] = prescriptions[rxType][nutrNo] || {};
            delete prescriptions[rxType][nutrNo].condition;
        });
    });

    // Fill in implicit values that are missing
    Object.keys(mealTypes).forEach((rxType) => {
        if (!prescriptions[rxType]) {
            return;
        }

        const implicitRx = computeDefaultMealRx({ envelope: prescriptions["all-day"] }, rxType);

        Object.keys(prescriptions[rxType]).forEach((nutrNo) => {
            const range = prescriptions[rxType][nutrNo];

            if (range.min || range.max || !implicitRx.envelope[nutrNo]) {
                return;
            }

            if (implicitRx.envelope[nutrNo].min) {
                range.min = implicitRx.envelope[nutrNo].min;
            }

            if (implicitRx.envelope[nutrNo].max) {
                range.max = implicitRx.envelope[nutrNo].max;
            }
        });
    });

    // Re-grab the kcal_min/kcal_max from the prescriptions
    kcal_min = prescriptions["all-day"]["208"].min || kcal_min;
    kcal_max = prescriptions["all-day"]["208"].max || kcal_max;

    // Use those final values to compute the percentages or g/kg for macros in all-day.
    ["203", "204", "205"].forEach((nutrNo) => {
        if (!prescriptions["all-day"][nutrNo]) {
            return;
        }

        const cpg = allNutrients[nutrNo].calories_per_gram;
        const range = prescriptions["all-day"][nutrNo];

        if (parametersUsed.includes("weight_kg")) {
            if (range.min) {
                range.g_per_kg_min = range.min / weight_kg;
            }

            if (range.max) {
                range.g_per_kg_max = range.max / weight_kg;
            }
        } else {
            if (range.min) {
                range.per_min = Math.round(((range.min * cpg) / kcal_min) * 100) / 100;
            }

            if (range.max) {
                range.per_max = Math.round(((range.max * cpg) / kcal_max) * 100) / 100;
            }
        }
    });

    return {
        conflicts,
        allDay: { meal_type: "all-day", envelope: prescriptions["all-day"] },
        breakfast: prescriptions.Breakfast ? { meal_type: "Breakfast", envelope: prescriptions.Breakfast } : null,
        lunch: prescriptions.Lunch ? { meal_type: "Lunch", envelope: prescriptions.Lunch } : null,
        dinner: prescriptions.Dinner ? { meal_type: "Dinner", envelope: prescriptions.Dinner } : null,
        snack: prescriptions.Snack ? { meal_type: "Snack", envelope: prescriptions.Snack } : null,
    };
}

export function areMacrosDefaulted(profile) {
    const { prescriptions = [] } = profile;
    const allDay = prescriptions.find((rx) => rx.meal_type === "all-day");

    // dAllDay = Default All Day, this is the compute macros for the prescription, without any RD interference.
    const { allDay: dAllDay } = computePrescriptionFromConditions(
        profile,
        profile.conditions.map((c) => c.name || c),
    );

    let isDefaulted = true;
    ["203", "204", "205"].forEach((nutrNo) => {
        if (dAllDay.envelope[nutrNo] && !allDay.envelope[nutrNo]) {
            isDefaulted = false;
            return;
        }

        if (!dAllDay.envelope[nutrNo] && allDay.envelope[nutrNo]) {
            isDefaulted = false;
            return;
        }

        if (!dAllDay.envelope[nutrNo] && !allDay.envelope[nutrNo]) {
            return; // good
        }

        if (
            (dAllDay.envelope[nutrNo].min && !allDay.envelope[nutrNo].min) ||
            (dAllDay.envelope[nutrNo].max && !allDay.envelope[nutrNo].max)
        ) {
            isDefaulted = false;
            return;
        }

        if (
            (dAllDay.envelope[nutrNo].min && allDay.envelope[nutrNo].min === dAllDay.envelope[nutrNo].min) ||
            (!dAllDay.envelope[nutrNo].min && !allDay.envelope[nutrNo].min) ||
            (dAllDay.envelope[nutrNo].max && allDay.envelope[nutrNo].max === dAllDay.envelope[nutrNo].max) ||
            (!dAllDay.envelope[nutrNo].max && !allDay.envelope[nutrNo].max)
        ) {
            return;
        }

        isDefaulted = false;
    });

    return isDefaulted;
}

/**
 * Function determines if a profile is eligible for macro adjustment therapy.
 *
 * @param  {Array}  conditions [description]
 * @param  {Array}  diets      [description]
 * @return {[type]}            [description]
 */
export function shouldAdjustMacrosForVeggies(profile, diets = null) {
    const { conditions = [], prescriptions = [], preferences = {} } = profile;
    diets = diets || preferences.diets || [];

    const veggieAvoidances = {
        Vegan: ["beef", "dairy", "eggs", "fish", "game_meats", "lamb", "pork", "poultry", "shellfish"],
        "Vegetarian (Lacto-ovo)": ["beef", "fish", "game_meats", "lamb", "pork", "poultry", "shellfish"],
        "Lacto-Vegetarian": ["beef", "eggs", "fish", "game_meats", "lamb", "pork", "poultry", "shellfish"],
        "Ovo-Vegetarian": ["beef", "dairy", "fish", "game_meats", "lamb", "pork", "poultry", "shellfish"],
    };

    const veggies = Object.keys(veggieAvoidances);

    const isVeggieAvoidances = Object.values(veggieAvoidances).some((avoidanceList) =>
        avoidanceList.every((avoidance) => preferences?.avoidances.includes(avoidance)),
    );

    const isVeggie = isVeggieAvoidances || (diets || []).filter((d) => veggies.includes(d)).length > 0;

    // Is this one of the conditions we're modifying?
    const condNames = (conditions || []).map((d) => d.name || d);
    const intersect = [
        "General Healthy Diet",
        "Overweight/Obesity",
        "Celiac Disease",
        "Diverticulosis",
        "Pregnancy",
        "Lactation",
        "Hypertension",
        "Prediabetes",
        "Low Sodium",
        "Eat More Veggies",
        "Increase Energy",
    ].filter((name) => condNames.includes(name));

    let allDay = (prescriptions || []).find((rx) => rx.meal_type === "all-day");

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return false;
    }

    return isVeggie && intersect.length > 0;
}

export function isPrescriptionAdjustedForVeggies(prescriptions) {
    let allDay = (prescriptions || []).find((rx) => rx.meal_type === "all-day");

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return false;
    }

    const protein = allDay.envelope["203"],
        fat = allDay.envelope["204"],
        carbs = allDay.envelope["205"];

    return (
        protein &&
        protein.per_min == 0.1 &&
        protein.per_max == 0.2 &&
        fat &&
        fat.per_min == 0.3 &&
        fat.per_max == 0.4 &&
        carbs &&
        carbs.per_min == 0.45 &&
        carbs.per_max == 0.55
    );
}

/**
 * Perform macro adjustment therapy. This adjusts protein down by 5% and carbs up by 5%.
 *
 * @param  {[type]} patient       [description]
 * @param  {[type]} prescriptions [description]
 * @return {[type]}               [description]
 */
export function adjustMacrosForVeggies(prescriptions) {
    // Adjust macros, 203 -5%, 205 +5%
    let allDay = (prescriptions || []).filter((rx) => rx.meal_type === "all-day")[0];

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return prescriptions;
    }

    // Re-grab the kcal_min/kcal_max from the prescriptions
    const kcal_min = allDay.envelope["208"].min;
    const kcal_max = allDay.envelope["208"].max;

    // Protein 10-20
    if (allDay.envelope["203"]) {
        allDay.envelope["203"] = {
            min: roundForHumans((kcal_min / 4) * 0.1),
            max: roundForHumans((kcal_max / 4) * 0.2),
            per_min: 0.1,
            per_max: 0.2,
        };
    }

    // Fat 30-40
    if (allDay.envelope["204"]) {
        allDay.envelope["204"] = {
            min: roundForHumans((kcal_min / 9) * 0.3),
            max: roundForHumans((kcal_max / 9) * 0.4),
            per_min: 0.3,
            per_max: 0.4,
        };
    }

    // Carbohydrates 45-55
    if (allDay.envelope["205"]) {
        allDay.envelope["205"] = {
            min: roundForHumans((kcal_min / 4) * 0.45),
            max: roundForHumans((kcal_max / 4) * 0.55),
            per_min: 0.45,
            per_max: 0.55,
        };
    }

    return prescriptions;
}

export function shouldAdjustMacrosForPescatarians(profile, diets = null) {
    const { conditions = [], prescriptions = [], preferences = {} } = profile;
    diets = diets || preferences.diets || [];

    const pescatarians = ["Pescatarian"];
    const isPescatarian = (diets || []).filter((d) => pescatarians.includes(d)).length > 0;

    // Is this one of the conditions we're modifying?
    const condNames = (conditions || []).map((d) => d.name || d);
    const intersect = [
        "General Healthy Diet",
        "Overweight/Obesity",
        "Celiac Disease",
        "Diverticulosis",
        "Pregnancy",
        "Lactation",
        "Hypertension",
        "Prediabetes",
        "Low Sodium",
        "Eat More Pescatarians",
        "Increase Energy",
    ].filter((name) => condNames.includes(name));

    let allDay = (prescriptions || []).find((rx) => rx.meal_type === "all-day");

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return false;
    }

    return isPescatarian && intersect.length > 0;
}

export function isPrescriptionAdjustedForPescatarians(prescriptions) {
    let allDay = (prescriptions || []).find((rx) => rx.meal_type === "all-day");

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return false;
    }

    const protein = allDay.envelope["203"],
        fat = allDay.envelope["204"],
        carbs = allDay.envelope["205"];

    return (
        protein &&
        protein.per_min == 0.13 &&
        protein.per_max == 0.23 &&
        fat &&
        fat.per_min == 0.31 &&
        fat.per_max == 0.41 &&
        carbs &&
        carbs.per_min == 0.41 &&
        carbs.per_max == 0.51
    );
}

export function adjustMacrosForPescatarians(prescriptions) {
    // Adjust macros, 203 -5%, 205 +5%
    let allDay = (prescriptions || []).filter((rx) => rx.meal_type === "all-day")[0];

    if (!(allDay && allDay.envelope["208"] && allDay.envelope["208"].min && allDay.envelope["208"].max)) {
        return prescriptions;
    }

    // Re-grab the kcal_min/kcal_max from the prescriptions
    const kcal_min = allDay.envelope["208"].min;
    const kcal_max = allDay.envelope["208"].max;

    // Protein 10-20
    if (allDay.envelope["203"]) {
        allDay.envelope["203"] = {
            min: roundForHumans((kcal_min / 4) * 0.13),
            max: roundForHumans((kcal_max / 4) * 0.23),
            per_min: 0.13,
            per_max: 0.23,
        };
    }

    // Fat 30-40
    if (allDay.envelope["204"]) {
        allDay.envelope["204"] = {
            min: roundForHumans((kcal_min / 9) * 0.31),
            max: roundForHumans((kcal_max / 9) * 0.41),
            per_min: 0.31,
            per_max: 0.41,
        };
    }

    // Carbohydrates 45-55
    if (allDay.envelope["205"]) {
        allDay.envelope["205"] = {
            min: roundForHumans((kcal_min / 4) * 0.41),
            max: roundForHumans((kcal_max / 4) * 0.51),
            per_min: 0.41,
            per_max: 0.51,
        };
    }

    return prescriptions;
}

export function resetMacroRxToConditionDefault(profile) {
    const { prescriptions = [] } = profile;
    const allDay = prescriptions.find((rx) => rx.meal_type === "all-day");

    // dAllDay = Default All Day, this is the compute macros for the prescription, without any RD interference.
    const defaultRxs = computePrescriptionFromConditions(
        profile,
        profile.conditions.map((c) => c.name),
    );

    ["203", "204", "205"].forEach((nutrNo) => {
        allDay.envelope[nutrNo] = defaultRxs.allDay.envelope[nutrNo];
    });

    return prescriptions;
}

export function convertEnvelopeToFilters(envelope, min = "min", max = "max", prefix = "", factor = 1) {
    const filters = {};

    Object.keys(envelope).forEach((nutrNo) => {
        const nutrient = allNutrients[nutrNo];

        if (!(nutrient && nutrient.Filter)) {
            return;
        }

        const range = {};

        if (typeof envelope[nutrNo].min === "number") {
            range[min] = envelope[nutrNo].min / factor;
        }

        if (typeof envelope[nutrNo].max === "number") {
            range[max] = envelope[nutrNo].max / factor;
        }

        filters[prefix + nutrient.Filter] = range;
    });

    return filters;
}

// function convertEnvelopeToMinMaxFilters(envelope, prefix = '', factor = 1) {
//     const filters = {};

//     Object.keys(envelope).forEach(nutrNo => {
//         const nutrient = allNutrients[nutrNo];

//         if (!(nutrient && nutrient.Filter)) {
//             return;
//         }

//         if (envelope[nutrNo].min >= 0) {
//             filters[prefix + nutrient.Filter + '.min'] = {gte: envelope[nutrNo].min / factor};
//         }

//         if (envelope[nutrNo].max >= 0) {
//             filters[prefix + nutrient.Filter + '.max'] = {lte: envelope[nutrNo].max / factor};
//         }
//     });

//     return filters;
// }

export function getGeneratorConstraintsForProfile(prescriptions, factor) {
    const allDayRx = prescriptions.filter((p) => p.meal_type === "all-day")[0];

    const constraints = {
        "all-day": allDayRx ? convertEnvelopeToFilters(allDayRx.envelope, "min", "max", "", factor) : {},
    };

    Object.keys(mealTypes).forEach((mealType) => {
        const mt = mealTypes[mealType];

        // If there's no meal
        if (!mt) {
            return;
        }

        // Find the prescription for this meal type, convert it to filters and add it to the parameters
        const prescription = prescriptions.filter((p) => p.meal_type === mealType)[0];

        // No prescription for this meal type. Skip
        if (!(prescription && prescription.envelope)) {
            return;
        }

        constraints[mealType] = convertEnvelopeToFilters(prescription.envelope, "min", "max", "", factor);
    });

    return constraints;
}

// strict_rx
export function getSearchFiltersForProfile(profile, filters = {}, factor = 1) {
    filters.strict_rx = [];

    (profile.prescriptions || []).forEach((rx) => {
        // Divide everything in the prescription by the factor
        filters.strict_rx.push({
            meal_type: rx.meal_type,
            envelope: factorEnvelope(rx.envelope, factor),
        });
    });

    return filters;
}

export function addPrescriptionFiltersToParams(profile, params, mealType) {
    const allDayRx = profile && profile.prefrences && profile.preferences.daily_totals;
    let mealRx = mealType.envelope;

    if (!allDayRx || !mealRx) {
        return params;
    }

    Object.assign(params.filters, convertEnvelopeToFilters(mealRx, "gte", "lte", "", profile.portion));

    return params;
}

export function setRecommendedModeSourceParameters(mode, params, profile) {
    const {
        recommendation_mode_source
    } = profile;

    if (recommendation_mode_source && recommendation_mode_source[mode]) {
        const { boost, filter, exclude } = recommendation_mode_source[mode];

        const boostMerchants = boost?.filter(o => o?.source_type === "merchant");

        if (boostMerchants?.length) {
            params.sort_params.boosts = boostMerchants.map(o => ({
                "merchant.uuid": o.uuid,
                "weight": o.meta?.boost_value,
            }));

            params.include_merchants = boostMerchants.map(o => o.uuid);
        }

        const filterMerchants = filter?.filter(o => o?.source_type === "merchant");

        if (filterMerchants?.length) {
            params.filters = {
                ...params.filters,
                "merchant.uuid": filterMerchants.map(o => o.uuid),
            };
        }

        const excludeMerchants = exclude?.filter((obj) => obj?.source_type === "merchant");
        const excludeMerchantsMap = excludeMerchants?.map((obj) => obj?.uuid);

        if (excludeMerchants) {
            params.filters = {
                ...params.filters,
                "!merchant.uuid": excludeMerchantsMap,
            };
        }
    }
}

export function getMealSearchParamsForProfile(mealType, profile) {
    const user = UserStore.getUser();
    const { preferences = {} } = profile;

    const {
        diets = [],
        avoidances = [],
        exclude_foods = [],
        equipment = [],
        skill_level,
        limit_tags = [],
        avoid_cuisines = [],
        prefer_cuisines = [],
        prefer_milks = [],
        prefer_rices = [],
    } = preferences;

    const excludeTags = []
        .concat(avoid_cuisines)
        .concat(allTags.equipment.tags.filter((tag) => !equipment.includes(tag)));

    let params = {
        types: ["recipe", "combo"],
        filters: {},
        sort_by: "ideal_nutrients",
        sort_params: {
            prefers: prefer_milks.concat(prefer_rices).concat(prefer_cuisines),
            avoidances: avoidances.slice(0),
        },
        include_merchants: user?.features?.source_libraries || null
    };

    if (limit_tags.length) {
        params.filters["tags"] = limit_tags.slice(0);
    }

    if (excludeTags.length) {
        params.filters["!tags"] = excludeTags;
    }

    if (avoidances.length) {
        params.filters["!ingredient_tags"] = avoidances.slice(0);
    }

    if (exclude_foods) {
        params.filters["!foods"] = exclude_foods.slice(0);
    }

    if (profile.pregnant) {
        params.filters["!tags"] = params.filters["!tags"] || [];
        params.filters["!tags"].push("NOPREG");
        params.filters["!ingredient_tags"].push("NOPREG");
    }

    getDietsFromTags(diets).forEach((diet) => {
        // Find the intersection of the diet avoidances and the prefs.avoidances
        var intersect = diet.avoidances.filter((avoidance) => avoidances.includes(avoidance));
        if (intersect.length === diet.avoidances.length) {
            params.filters.tags = params.filters.tags || [];
            params.filters.tags.push(diet.name);
        }
    });

    // If the profile is a kid, use only kid friendly recipes. This will
    // severely limit the amount of content available to generate with, but
    // is better than showing wildly inappropriate recipes for a kid.
    if (profile.birthdate) {
        const age = moment().diff(profile.birthdate, "year");

        if (age <= 12) {
            params.filters.tags = params.filters.tags || [];
            params.filters.tags.push("Kid Friendly");
        }
    }

    if (skill_level === "Beginner") {
        params.filters["!tags"] = params.filters["!tags"] || [];
        params.filters["!tags"].push("Intermediate");
        params.filters["!tags"].push("Advanced");
    }

    if (skill_level === "Intermediate") {
        params.filters["!tags"] = params.filters["!tags"] || [];
        params.filters["!tags"].push("Advanced");
    }

    if (mealType.main_dish == "Breakfast") {
        params.filters.tags = params.filters.tags || [];
        params.filters.tags.push("Breakfast");
    }
    if (mealType.main_dish == "Lunch") {
        params.filters.tags = params.filters.tags || [];
        params.filters.tags.push("Lunch");
    }
    if (mealType.main_dish == "Dinner") {
        params.filters.tags = params.filters.tags || [];
        params.filters.tags.push("Main Dish");
    }
    if (mealType.main_dish == "Snack") {
        params.types = ["recipe"];
        params.filters.tags = params.filters.tags || [];
        params.filters.tags.push("Snack");
    }

    addPrescriptionFiltersToParams(profile, params, mealType);

    return params;
}

export function getParamsForProfile(profile) {
    const { preferences = {}, prescriptions = [], recommendation_mode_source, shopping_freq = 7, language = 'en', portion = 1 } = profile;

    const {
        diets = [],
        avoidances = [],
        exclude_foods = [],
        limit_tags = [],
        skill_level,
        equipment = [],
        avoid_cuisines = [],
        prefer_cuisines = [],
        prefer_milks = [],
        prefer_rices = [],
        breakfast_keywords,
        lunch_keywords,
        dinner_keywords,
        snack_keywords,
        avoid_keywords,
        breakfast_max_time,
        lunch_max_time,
        dinner_max_time,
        meal_kit_providers = [],
        breakfast_max_cost_per_serving,
        lunch_max_cost_per_serving,
        snack_max_cost_per_serving,
        dinner_max_cost_per_serving,
    } = preferences;

    const excludeTags = []
        .concat(avoid_cuisines)
        .concat(allTags.equipment.tags.filter((tag) => !equipment.includes(tag)));

    let params = {
        language,
        portion,
        types: ["plan"],
        filters: {
            "!tags": ["Exclude from Virtual", "Ready Made Meal"],
        },
    };

    if (profile.uuid) {
        params.for_patient = profile.uuid;
    }

    if (limit_tags.length) {
        params.filters["tags"] = diets.concat(limit_tags);
    }

    if (excludeTags.length) {
        params.filters["!tags"] = params.filters["!tags"].concat(excludeTags);
    }

    if (avoidances.length) {
        params.filters["!ingredient_tags"] = avoidances.slice(0);
    }

    if (exclude_foods) {
        params.filters["!foods"] = exclude_foods.slice(0);
    }

    if (profile.pregnant) {
        params.filters["!tags"].push("NOPREG");
        params.filters["!ingredient_tags"] = params.filters["!ingredient_tags"] || [];
        params.filters["!ingredient_tags"].push("NOPREG");
    }

    // If the profile is a kid, use only kid friendly recipes. This will
    // severely limit the amount of content available to generate with, but
    // is better than showing wildly inappropriate recipes for a kid.
    if (profile.birthdate) {
        const age = moment().diff(profile.birthdate, "year");

        if (age <= 12) {
            params.filters.tags = params.filters.tags || [];
            params.filters.tags.push("Kid Friendly");
        }
    }

    if (skill_level === "Beginner") {
        params.filters["!tags"].push("Intermediate");
        params.filters["!tags"].push("Advanced");
    }

    if (skill_level === "Intermediate") {
        params.filters["!tags"].push("Advanced");
    }

    params.conditions = (profile.conditions || [])
        .map((c) => allConditions.find((cd) => cd.name == (c.name || c)))
        .filter((v) => v)
        .map((c) => c.name);
    params.constraints = getGeneratorConstraintsForProfile(prescriptions, profile.portion);

    params.prefers = prefer_milks.concat(prefer_rices).concat(prefer_cuisines);

    const countParticipants = (total, member) => total + member.portion;

    const participation = {
        breakfasts: getParticipantsForProfileByMealType(profile, "Breakfast").reduce(countParticipants, 0),
        lunches: getParticipantsForProfileByMealType(profile, "Lunch").reduce(countParticipants, 0),
        dinners: getParticipantsForProfileByMealType(profile, "Dinner").reduce(countParticipants, 0),
        snacks: getParticipantsForProfileByMealType(profile, "Snack").reduce(countParticipants, 0),
    };

    // Do we have other adults or kids in the family?
    if (participation.breakfasts) {
        params.breakfast_participants = Math.ceil(participation.breakfasts);
        params.breakfasts = shopping_freq;
    }

    if (participation.lunches) {
        params.lunch_participants = Math.ceil(participation.lunches);
        params.lunches = shopping_freq;
    }

    if (participation.dinners) {
        params.dinner_participants = Math.ceil(participation.dinners);
        params.dinners = shopping_freq;
    }

    if (participation.snacks) {
        params.snack_participants = Math.ceil(participation.snacks);
        params.snacks = shopping_freq;
    }

    if (!(profile.preferences && profile.preferences.leftovers_enabled)) {
        params.leftovers_enabled = false;
    } else {
        params.leftovers_enabled = true;
        params.max_leftover_days = profile.preferences.max_leftover_days;
    }

    if (breakfast_keywords) {
        params.breakfast_terms = breakfast_keywords;
    }

    if (lunch_keywords) {
        params.lunch_terms = lunch_keywords;
    }

    if (dinner_keywords) {
        params.dinner_terms = dinner_keywords;
    }

    if (snack_keywords) {
        params.snack_terms = snack_keywords;
    }

    if (avoid_keywords) {
        params.avoid_terms = avoid_keywords;
    }

    if (breakfast_max_time) {
        params.breakfast_max_time = breakfast_max_time;
    }

    if (lunch_max_time) {
        params.lunch_max_time = lunch_max_time;
    }

    if (dinner_max_time) {
        params.dinner_max_time = dinner_max_time;
    }

    if (breakfast_max_cost_per_serving) {
        params.breakfast_max_cost_per_serving = breakfast_max_cost_per_serving;
    }

    if (lunch_max_cost_per_serving) {
        params.lunch_max_cost_per_serving = lunch_max_cost_per_serving;
    }

    if (snack_max_cost_per_serving) {
        params.snack_max_cost_per_serving = snack_max_cost_per_serving;
    }

    if (dinner_max_cost_per_serving) {
        params.dinner_max_cost_per_serving = dinner_max_cost_per_serving;
    }

    if (recommendation_mode_source?.plan_generator) {
        const { boost = [], filter = [], exclude = [] } = recommendation_mode_source?.plan_generator;

        params.boosts = boost.map((source) => {
            const boost = { weight: source?.meta?.boost_value };

            switch (source.source_type) {
                case "merchant":
                    boost["merchant.uuid"] = source.uuid;
                    break;
            }

            return boost;
        });

        params.include_merchants = boost.filter(s => s.source_type === 'merchant').map(s => s.uuid);

        filter.forEach((source) => {
            switch (source.source_type) {
                case "merchant":
                    params.filters["merchant.uuid"] = params.filters["merchant.uuid"] || [];
                    params.filters["merchant.uuid"].push(source.uuid);
                    break;
            }
        });

        exclude.forEach((source) => {
            switch (source.source_type) {
                case "merchant":
                    params.filters["!merchant.uuid"] = params.filters["!merchant.uuid"] || [];
                    params.filters["!merchant.uuid"].push(source.uuid);
                    break;
            }
        });
    }

    return params;
}

const STORED_PLANS_KEY = "patient-recommended-plans";

export function removeStoredPlans(patient) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    const key = [STORED_PLANS_KEY, patient.uuid, user.uuid].join("-");

    LocalStorage.remove(key);
}

export function loadStoredPlans(patient) {
    const user = UserStore.getUser();

    if (!user) {
        return Promise.reject();
    }

    const key = [STORED_PLANS_KEY, patient.uuid, user.uuid].join("-");
    let memory = LocalStorage.get(key) || {};
    let { plans = [], virtual = [], total = 0, expires = null, lastSearchParams, lastGenParams } = memory;

    // If a virtual plan in the list is no longer available, remove it.
    virtual = virtual.map((uuid) => fetchVirtualPlan(uuid)).filter((v) => v);

    // Stored plans will contain an array of IDs. We need to load the ones not marked
    // virtual and pull the other ones from the virtual meal plan store.
    return new Promise((accept, reject) => {
        // If there is no expires or it is expired, return nothing and delete.
        if (!expires || (expires && moment().isAfter(expires))) {
            removeStoredPlans(patient);

            return accept({
                plans: [],
                virtual: [],
                total: 0,
                lastSearchParams: null,
                lastGenParams: null,
            });
        }

        if (!plans.length) {
            return accept({
                plans: [],
                virtual,
                total,
                lastSearchParams,
                lastGenParams,
            });
        }

        fetchDocumentsById(plans).then((documents) => {
            return accept({
                plans: documents,
                virtual,
                total,
                lastSearchParams,
                lastGenParams,
            });
        });
    });
}

export function setStoredPlans(patient, virtual, lastGenParams) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    // Expire in 42 days
    const expires = moment().add(42, "day");
    const key = [STORED_PLANS_KEY, patient.uuid, user.uuid].join("-");

    LocalStorage.set(
        key,
        {
            virtual: virtual.filter((v) => v).map((v) => v.uuid),
            expires: expires.format(),
            lastGenParams,
        },
        expires.toDate().getTime(),
    );
}

const HIDE_PATIENT_KEY = "patient-feed";

export function hidePatientFor(patient, days) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    const expires = moment().add(days, "day").format();
    const key = [HIDE_PATIENT_KEY, patient.uuid, user.uuid].join("-");

    LocalStorage.set(key, { expires });
}

export function isPatientHidden(patient) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    const key = [HIDE_PATIENT_KEY, patient.uuid, user.uuid].join("-");

    let { expires } = LocalStorage.get(key) || {};

    if (!expires || (expires && moment().isAfter(expires))) {
        LocalStorage.remove(key);

        return false;
    }

    return true;
}

export function isPatientChild(patient) {
    const birthdate = (patient && patient.birthdate) || null;

    if (!birthdate) {
        return false;
    }

    const age = roundForHumans(moment().diff(patient.birthdate, "years", true));

    return age <= 12;
}

export function getRecipeMismatchesForProfile(recipe, meal_type, profile) {
    let mismatches = [];

    if (!profile) {
        return mismatches;
    }

    const { prescriptions = [], portion = 1 } = profile;

    let prescription = prescriptions.filter((p) => p.meal_type === meal_type)[0];

    if (!prescription) {
        prescription = prescriptions.filter((p) => p.meal_type === "all-day")[0];
    }

    // If we STILL don't have a prescription, I guess we pass?
    if (!prescription || !(recipe.nutrients && recipe.nutrients.values)) {
        return mismatches;
    }

    Object.keys(prescription.envelope).forEach((nutrNo) => {
        const { min = 0, max = 99999 } = prescription.envelope[nutrNo];

        const value = (Math.round((recipe.nutrients.values[nutrNo] || 0) * 100) / 100) * portion;

        // If the recipe has too much of something, disqualify it
        if (value > max) {
            mismatches.push({
                meal_type: prescription.meal_type,
                nutrNo,
                value,
                min,
                max,
            });
        }
    });

    return mismatches;
}

export function getPlanMismatchesForProfile(plan, prescriptions) {
    return [];
}

const alwaysShowNutrNos = [
    "208", // calories
    "205", // carbs
    "203", // protein
    "204", // fat
    "606", // saturated fat
    "605", // trans fat
    "601", // cholesterol
    "307", // sodium
    "291", // fiber
    "269", // total sugar
    "ASG", // added sugar
    "324", // vitamin d
    "301", // calcium
    "303", // iron
    "306", // potassium
    "305", // phosphorus
    "FRU", // fruits
    "VEG", // vegetables
];

export function getNutrNosForProfile(profile) {
    const nutrNos = alwaysShowNutrNos.slice();
    const { prescriptions = [], portion = 1, target_energy_kcal = 2000 } = profile;
    const allDay = (prescriptions || []).find((p) => p.meal_type === "all-day") || { envelope: {} };

    Object.keys(allDay.envelope).forEach((nutrNo) => {
        if (!nutrNos.includes(nutrNo)) {
            nutrNos.push(nutrNo);
        }
    });

    nutrNos.sort(nutrNoSortCmp);

    return nutrNos;
}

export function getNutrientsToDisplay(profile, values, unavailableDefault = 0, showAllAvailable) {
    const demographic = getDemographic(profile);
    const dri = {
        ...allRecommendations["all-ages"],
        ...allRecommendations[demographic],
    };

    // Initialize nutrients and set to their DRI values for this profile
    const nutrients = {};
    alwaysShowNutrNos.forEach((nutrNo) => {
        nutrients[nutrNo] = {
            v: values[nutrNo] == null ? unavailableDefault : values[nutrNo],
            t: dri[nutrNo] || unavailableDefault,
        };
    });

    // If there is no profile, show all the default nutrients.
    if (!(profile && profile.prescriptions)) {
        return nutrients;
    }

    const { prescriptions = [], portion = 1, target_energy_kcal = 2000 } = profile;

    // Update the pro/cho/fat DRIs to fit with the target energy needs
    dri[203] = Math.round((target_energy_kcal * 0.2) / 4); // calories from protein should be 20%
    dri[204] = Math.round((target_energy_kcal * 0.3) / 9); // total fat should be 30% of daily calorie intake
    dri[205] = Math.round((target_energy_kcal * 0.5) / 4); // total carbs should be 50% of daily calorie intake

    // Just use the all-day prescription for now
    const allDay = (prescriptions || []).filter((p) => p.meal_type === "all-day")[0] || { envelope: {} };

    let nonDefaultNutrientsToDisplay = Object.keys(allDay.envelope);

    nonDefaultNutrientsToDisplay = nonDefaultNutrientsToDisplay.concat(alwaysShowNutrNos.filter(
        nutrNo => !nonDefaultNutrientsToDisplay.includes(nutrNo)
    ));

    if (showAllAvailable) {
        const otherNutrients = Object.keys(values).filter(
            (nutrNo) => !alwaysShowNutrNos.includes(nutrNo) && !nonDefaultNutrientsToDisplay.includes(nutrNo),
        );
        nonDefaultNutrientsToDisplay = nonDefaultNutrientsToDisplay.concat(otherNutrients);
    }

    nonDefaultNutrientsToDisplay.forEach((nutrNo) => {
        const range = allDay.envelope[nutrNo];

        let target = dri[nutrNo];
        let value = values[nutrNo] == null ? unavailableDefault : values[nutrNo];
        let targetName = null;
        let trackedOnly = false;

        if (value != null) {
            value = value * portion;
        }

        if (range && range.min && range.max) {
            target = (range.min + range.max) / 2;
            targetName = "goal";
        } else if (range && range.max) {
            target = range.max;
            targetName = "max";
        } else if (range && range.min) {
            target = range.min;
            targetName = "goal";
        } else {
            target = dri[nutrNo] || unavailableDefault;
        }

        // Consider it 100%
        if (!target || isNaN(target)) {
            target = dri[nutrNo] || value;
            trackedOnly = true;
        }

        nutrients[nutrNo] = {
            v: value,
            t: target,
            targetName,
            trackedOnly,
        };
    });

    return nutrients;
}

// Can accept a patient or a user. Both the schemas mirror where it counts
export function getConfigurationIssues(patient) {
    let { target_energy_kcal, birthdate, gender, conditions = [], preferences = {}, portion = 1, portion_resolution } = patient;
    let { diets = [], avoidances = [], limit_tags = [] } = preferences;

    // Default age to 25 if we don't have a birthdate
    const age = birthdate ? moment().diff(birthdate, "years") : 25;

    // Convert conditions to array of conditions and condition names
    const conditionNames = conditions.map((c) => c.name);

    // Determine if user is vegetarian
    const veggies = ["Vegetarian (Lacto-ovo)", "Lacto-Vegetarian", "Ovo-Vegetarian"];
    const isVegetarian = diets.filter((d) => veggies.includes(d)).length > 0;

    const warnings = [];
    const errors = [];

    // Iron Deficient Anemia
    if (conditionNames.includes("Anemia Iron Deficient") && diets.includes("Vegan") && age <= 12) {
        warnings.push(
            "Iron deficient anemia vegan diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Anemia Iron Deficient") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades")
    ) {
        warnings.push(
            "Low calorie plans for iron deficient anemia and avoiding nightshades are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Anemia Iron Deficient") &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for iron deficient anemia avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Anemia Iron Deficient") &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for iron deficient anemia avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // if (conditionNames.includes('Anemia Iron Deficient') && target_energy_kcal < 1200 && target_energy_kcal > 200 && age <= 12 &&
    //     avoidances.includes('nightshades') && avoidances.includes('wheat') && avoidances.includes('milk')) {
    //     warnings.push('Plans for pediatric iron deficient anemia and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.');
    // }

    // if (conditionNames.includes('Anemia Iron Deficient') && target_energy_kcal < 1200 && target_energy_kcal > 200 && age <= 12 &&
    //     avoidances.includes('corn') && avoidances.includes('wheat') && avoidances.includes('milk')) {
    //     warnings.push('Plans for pediatric iron deficient anemia avoiding corn, wheat and milk are not fully supported at the moment. We’re adding more content daily. Expect limited results.');
    // }

    // B-12 Deficient Anemia
    if (conditionNames.includes("Anemia B12 Deficient") && diets.includes("Vegan")) {
        errors.push(
            "We're sorry, the B-12 deficient anemia condition is incompatible with the Vegan diet. Please revise your profile.",
        );
    }

    if (
        conditionNames.includes("Anemia B12 Deficient") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Lower calorie plans for B-12 deficient anemia avoiding nightshades, wheat and dairy are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Anemia B12 Deficient") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        age <= 12 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for pediatric B-12 deficient anemia and avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Anemia B12 Deficient") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for pediatric B-12 deficient anemia and avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Bariatric Stage 4
    if (conditionNames.includes("Bariatric Stage 4") && diets.includes("Vegan")) {
        warnings.push(
            "Bariatric vegan diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Bariatric Stage 4") && isVegetarian) {
        warnings.push(
            "Bariatric vegetarian diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    // Chronic Kidney Disease (CKD)
    if (
        conditionNames.includes("CKD Stage 1-2") &&
        isVegetarian &&
        avoidances.includes("treenuts") &&
        avoidances.includes("wheat")
    ) {
        warnings.push(
            "Kidney-friendly vegetarian plans with no treenuts and gluten free are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("CKD Stage 1-2") && target_energy_kcal > 2500 && target_energy_kcal > 200) {
        warnings.push(
            "Higher calorie kidney-friendly diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("soy")
    ) {
        warnings.push(
            "Plans for this profile are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        target_energy_kcal > 2200 &&
        target_energy_kcal > 200 &&
        avoidances.includes("wheat")
    ) {
        warnings.push(
            "Plans for this profile are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("CKD Stage 1-2") && diets.includes("Vegan")) {
        warnings.push(
            "Kidney-friendly vegan diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        avoidances.includes("avocado") &&
        avoidances.includes("cilantro")
    ) {
        warnings.push(
            "Low calorie plans for CKD and avoiding avocados and cilantro are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        target_energy_kcal > 2000 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades")
    ) {
        warnings.push(
            "High calorie plans for CKD avoiding nightshades are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for CKD with these avoidances are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("CKD Stage 1-2") &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for CKD with these avoidances are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Diabetes
    if (
        isVegetarian &&
        age <= 12 &&
        (conditionNames.includes("Diabetes Type 1") || conditionNames.includes("Diabetes Type 2"))
    ) {
        warnings.push(
            "Plans for kid friendly, vegetarian and diabetes are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        target_energy_kcal < 1100 &&
        target_energy_kcal > 200 &&
        age <= 12 &&
        avoidances.includes("milk") &&
        (conditionNames.includes("Diabetes Type 1") || conditionNames.includes("Diabetes Type 2"))
    ) {
        warnings.push(
            "Low calorie dairy free diabetes profiles are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        diets.includes("Vegan") &&
        (conditionNames.includes("Diabetes Type 1") || conditionNames.includes("Diabetes Type 2"))
    ) {
        warnings.push(
            "Low calorie vegan diabetes profiles are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        (conditionNames.includes("Diabetes Type 1") || conditionNames.includes("Diabetes Type 2")) &&
        age <= 12 &&
        diets.includes("Vegan")
    ) {
        errors.push(
            "We're sorry, the diabetes conditions are incompatible with the vegan diet for children. Please revise your profile.",
        );
    }

    if (
        (conditionNames.includes("Diabetes Type 1") || conditionNames.includes("Diabetes Type 2")) &&
        isVegetarian &&
        avoidances.includes("treenuts") &&
        avoidances.includes("wheat")
    ) {
        warnings.push(
            "Diabetes vegetarian plans with no treenuts and gluten free are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    // Prediabetes
    if (
        conditionNames.includes("Prediabetes") &&
        isVegetarian &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        age <= 12
    ) {
        warnings.push(
            "Low calorie vegetarian plans for prediabetes are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Prediabetes") && diets.includes("Vegan") && age <= 12) {
        warnings.push(
            "Vegan plans for prediabetes are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Prediabetes") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        avoidances.includes("milk") &&
        age <= 12
    ) {
        warnings.push(
            "Low calorie plans for prediabetes avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Prediabetes") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        avoidances.includes("eggs") &&
        age <= 12
    ) {
        warnings.push(
            "Low calorie plans for prediabetes avoiding eggs are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Prediabetes") &&
        isVegetarian &&
        age <= 12 &&
        avoidances.includes("wheat") &&
        avoidances.includes("treenuts")
    ) {
        warnings.push(
            "Plans for vegetarian prediabetes avoiding wheat and treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // if (conditionNames.includes('Prediabetes') && target_energy_kcal < 1200 && target_energy_kcal > 200 && age <= 12 &&
    //     avoidances.includes('nightshades') && avoidances.includes('wheat') && avoidances.includes('milk')) {
    //     warnings.push('Low calorie plans for Prediabetes avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.');
    // }

    if (
        conditionNames.includes("Prediabetes") &&
        target_energy_kcal > 2000 &&
        age <= 12 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Higher calorie plans for prediabetes avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // if (conditionNames.includes('Prediabetes') && age <= 12 && target_energy_kcal < 1200 && target_energy_kcal > 200 &&
    //     avoidances.includes('corn') && avoidances.includes('wheat') && avoidances.includes('milk')) {
    //     warnings.push('Plans for pediatric Prediabetes avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.');
    // }

    if (
        conditionNames.includes("Prediabetes") &&
        target_energy_kcal > 2000 &&
        age <= 12 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for pediatric prediabetes avoiding corn, wheat and milk are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Diverticulitis
    if (conditionNames.includes("Diverticulitis") && isVegetarian) {
        warnings.push(
            "Low fiber vegetarian diets are not fully supported at the moment. It is challenging to fulfill a low fiber vegetarian diet with a whole foods approach. We are adding new content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Diverticulitis") && diets.includes("Vegan")) {
        errors.push(
            "We're sorry, the diverticulitis condition is incompatible with the vegan diet. It is challenging to fulfill a low fiber vegan diet with a whole foods approach. Please revise your profile.",
        );
    }

    if (
        conditionNames.includes("Diverticulitis") &&
        avoidances.includes("milk") &&
        target_energy_kcal > 2000 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Low fiber higher calorie dairy-free diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Diverticulitis") &&
        avoidances.includes("corn") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Low calorie plans for diverticulitis and avoiding corn are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Diverticulitis") &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk") &&
        target_energy_kcal > 1600 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Plans for diverticulitis and avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Diverticulitis") &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk") &&
        target_energy_kcal > 1500 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Plans for diverticulitis and avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Heart Failure
    if (conditionNames.includes("Heart Failure") && diets.includes("Vegan")) {
        warnings.push(
            "Vegan plans for heart failure are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Heart Failure") && avoidances.includes("milk")) {
        warnings.push(
            "Plans for heart failure and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Heart Failure") &&
        target_energy_kcal < 1600 &&
        target_energy_kcal > 200 &&
        avoidances.includes("soy")
    ) {
        warnings.push(
            "Low calorie plans for heart failure and avoiding soy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Heart Failure") &&
        target_energy_kcal < 1800 &&
        target_energy_kcal > 200 &&
        isVegetarian &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie vegetarian plans for heart failure and avoiding wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Heart Failure") &&
        target_energy_kcal < 1800 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for heart failure and avoiding nightshades, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Heart Failure") &&
        target_energy_kcal < 1800 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for heart failure and avoiding corn, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Hypertension
    if (
        conditionNames.includes("Hypertension") &&
        diets.includes("Vegan") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Plans for hypertension on a Vegan diet are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades")
    ) {
        warnings.push(
            "Low calorie plans for hypertension avoiding nightshades diet are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        isVegetarian &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200
    ) {
        warnings.push(
            "Low calorie vegetarian plans for hypertension are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("eggs")
    ) {
        warnings.push(
            "Low calorie plans for Hypertension and avoiding eggs are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("peanuts")
    ) {
        warnings.push(
            "Low calorie plans for Hypertension and avoiding peanuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("shellfish")
    ) {
        warnings.push(
            "Low calorie plans for Hypertension and avoiding shellfish are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn")
    ) {
        warnings.push(
            "Low calorie plans for Hypertension and avoiding corn are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypertension") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("avocado") &&
        avoidances.includes("cilantro")
    ) {
        warnings.push(
            "Low calorie plans for Hypertension and avoiding avocado and cilantro are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Hypermetabolism
    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 1600 &&
        target_energy_kcal > 200 &&
        diets.includes("Vegan")
    ) {
        warnings.push(
            "Low calorie vegan plans for hypermetabolism are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 1600 &&
        target_energy_kcal > 200 &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for hypermetabolism and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("soy")
    ) {
        warnings.push(
            "Low calorie plans for hypermetabolism and avoiding soy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 1900 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for hypermetabolism and avoiding corn, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal > 3000 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for hypermetabolism and avoiding corn, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 2000 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for hypermetabolism and avoiding nightshades, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal > 2800 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for hypermetabolism and avoiding nightshades, wheat, and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Hypermetabolism") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("treenuts")
    ) {
        warnings.push(
            "Low calorie plans for hypermetabolism and avoiding treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Low Sodium
    if (conditionNames.includes("Low Sodium") && isVegetarian && target_energy_kcal > 2000) {
        warnings.push(
            "Higher calorie low sodium vegetarian plans are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Low Sodium") && diets.includes("Vegan")) {
        warnings.push(
            "Low sodium vegan plans are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        isVegetarian &&
        avoidances.includes("wheat") &&
        avoidances.includes("treenuts")
    ) {
        warnings.push(
            "Vegetarian low calorie plans for low sodium and avoiding wheat and treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal > 2200 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for low sodium and avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Low Sodium") && isVegetarian && age <= 12) {
        warnings.push(
            "Vegetarian plans for pediatric low sodium are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        age <= 12 &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Plans for pediatric low sodium and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal > 2000 &&
        age <= 12 &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for pediatric low sodium and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal > 2200 &&
        age <= 12 &&
        avoidances.includes("treenuts")
    ) {
        warnings.push(
            "High calorie plans for pediatric low sodium and avoiding treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Low Sodium") && target_energy_kcal > 2200 && age <= 12 && avoidances.includes("soy")) {
        warnings.push(
            "High calorie plans for pediatric low sodium and avoiding soy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        age <= 12 &&
        avoidances.includes("corn")
    ) {
        warnings.push(
            "Plans for pediatric low sodium and avoiding corn are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal < 1200 &&
        target_energy_kcal > 200 &&
        age <= 12 &&
        avoidances.includes("nightshades")
    ) {
        warnings.push(
            "Plans for pediatric low sodium and avoiding nightshades are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal > 2000 &&
        age <= 12 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for pediatric low sodium and avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Low Sodium") &&
        target_energy_kcal > 1900 &&
        age <= 12 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "High calorie plans for pediatric low sodium and avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Osteoporosis
    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("nightshades") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for osteoporosis and avoiding nightshades, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn") &&
        avoidances.includes("wheat") &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for osteoporosis and avoiding corn, wheat and dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (conditionNames.includes("Osteoporosis") && diets.includes("Vegan")) {
        warnings.push(
            "Vegan plans for Osteoporosis are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("wheat")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding wheat are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1600 &&
        target_energy_kcal > 200 &&
        avoidances.includes("milk")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding dairy are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("eggs")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding eggs are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("treenuts")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("fish")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding treenuts are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1600 &&
        target_energy_kcal > 200 &&
        avoidances.includes("shellfish")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding shellfish are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    if (
        conditionNames.includes("Osteoporosis") &&
        target_energy_kcal < 1400 &&
        target_energy_kcal > 200 &&
        avoidances.includes("corn")
    ) {
        warnings.push(
            "Low calorie plans for Osteoporosis and avoiding corn are not fully supported at the moment. We’re adding more content daily. Expect limited results.",
        );
    }

    // Pregancy
    if (conditionNames.includes("Pregnancy") && diets.includes("Vegan") && target_energy_kcal > 2400) {
        warnings.push(
            "Higher calorie pregnancy vegan diets are not fully supported at the moment. We are adding new content daily. Expect limited results.",
        );
    }

    // Fat Loss
    if (conditionNames.includes("Fat Loss")) {
        if (diets.includes("Vegan") || isVegetarian) {
            errors.push(
                "We're sorry! Fat Loss lifestyle goal is not compatible with vegetarian or vegan diets due to the high protein requirement.",
            );
        }

        if (target_energy_kcal >= 2600) {
            errors.push(
                "We're sorry! Fat Loss lifestyle goal is not supported above 2600 calories per day. Please revise your profile.",
            );
        }
    }

    if (conditionNames.includes("Endurance")) {
        if (diets.includes("Vegan") && target_energy_kcal >= 3000) {
            warnings.push(
                "Endurance lifestyle goal is not fully supported for very high calorie vegans. We’re adding more content daily. Expect limited results!",
            );
        }
    }

    if (conditionNames.includes("Muscle Gain")) {
        if (diets.includes("Vegan") && target_energy_kcal > 2600) {
            errors.push(
                "We're sorry! Muscle Gain lifestyle goal is not supported for vegans. Please revise your profile.",
            );
        }

        if (isVegetarian && target_energy_kcal > 2600) {
            warnings.push(
                "Muscle Gain lifestyle goal is not fully supported for very high calorie vegans. We’re adding more content daily. Expect limited results!",
            );
        }

        if (diets.includes("Vegan") && target_energy_kcal <= 1500) {
            warnings.push(
                "Muscle Gain lifestyle goal is not fully supported for low calorie vegans. We’re adding more content daily. Expect limited results!",
            );
        }
    }

    if (conditionNames.includes("Low Carb/High Fat")) {
        if (diets.includes("Vegan")) {
            errors.push(
                "We're sorry! Low Carb/High Fat lifestyle goal is not supported for vegans. Please revise your profile.",
            );
        }

        if (isVegetarian) {
            warnings.push(
                "Low Carb/High Fat lifestyle goal is not fully supported for vegetarians. We’re adding more content daily. Expect limited results!",
            );
        }

        if (avoidances.includes("eggs") && target_energy_kcal >= 3500) {
            warnings.push(
                "Low Carb/High Fat lifestyle goal is not fully supported for those with high-calorie needs and avoiding eggs. We’re adding more content daily. Expect limited results!",
            );
        }

        if (avoidances.includes("treenuts") && target_energy_kcal >= 3500) {
            warnings.push(
                "Low Carb/High Fat lifestyle goal is not fully supported for those with high-calorie needs and avoiding treenuts. We’re adding more content daily. Expect limited results!",
            );
        }
    }

    if (conditionNames.includes("Low FODMAP") && avoidances.length > 0) {
        warnings.push(
            "Low FODMAP is not fully supported for those with many common food allergies and avoidances. Results may vary.",
        );
    }

    const basicConds = conditionNames.filter((cn) => basicMealConditions.includes(cn));

    if (limit_tags.includes("Basic") && basicConds.length !== conditionNames.length) {
        warnings.push("NOT RECOMMENDED: Given your nutrition requirements, we recommend turning Simple Recipes OFF.");
    } else if (target_energy_kcal < 1400 && limit_tags.includes("Basic")) {
        warnings.push(
            "Simple Recipes are not yet available for nutrition requirements of less than 1400 calories per day.",
        );
    } else if (target_energy_kcal >= 2400 && limit_tags.includes("Basic")) {
        warnings.push(
            "Simple Recipes are not yet available for nutrition requirements of more than 2400 calories per day.",
        );
    } else if (diets.includes("Vegan") && limit_tags.includes("Basic")) {
        warnings.push("Simple Recipes are not yet available for vegan diets.");
    } else if (avoidances.includes("wheat") && limit_tags.includes("Basic")) {
        warnings.push("Simple Recipes are not yet available for gluten free diets.");
    } else if (avoidances.includes("milk") && limit_tags.includes("Basic")) {
        warnings.push("Simple Recipes are not yet available for dairy free diets.");
    }

    // Let's see if we can get away with removing this limitation now. I bet this won't be a problem anymore.
    // const DEFAULT_PORTION_RESOLUTION = 0.25;

    // // Check to see if our portion size is within range
    // let expectedPortion = calculateNearestPortionSize(target_energy_kcal, portion_resolution || DEFAULT_PORTION_RESOLUTION);

    // if (expectedPortion === null && target_energy_kcal > 900) {
    //     errors.push("We're sorry, but EatLove does not fully support such large calorie needs yet.");
    // }

    return { warnings, errors };
}

export function getAssetsFromActivities(activities) {
    const uuids = [];

    activities.forEach((activity) => {
        const { event_data } = activity;

        if (!event_data) {
            return;
        }

        if (event_data.plan_uuid) {
            uuids.push(event_data.plan_uuid);
        }

        if (event_data["uuid"]) {
            uuids.push(event_data["uuid"]);
        }

        if (event_data["Meal Plan ID"]) {
            uuids.push(event_data["Meal Plan ID"]);
        }

        if (event_data["Meal Plan UUID"]) {
            uuids.push(event_data["Meal Plan UUID"]);
        }

        if (event_data.recipe_uuid) {
            uuids.push(event_data.recipe_uuid);
        }

        if (event_data["Recipe ID"]) {
            uuids.push(event_data["Recipe ID"]);
        }

        if (event_data["Recipe UUID"]) {
            uuids.push(event_data["Recipe UUID"]);
        }

        if (event_data["Replaced Recipe ID"]) {
            uuids.push(event_data["Replaced Recipe ID"]);
        }

        if (event_data["Replaced Recipe UUID"]) {
            uuids.push(event_data["Replaced Recipe UUID"]);
        }

        if (event_data["Replaced Side ID"]) {
            uuids.push(event_data["Replaced Side ID"]);
        }

        if (event_data["Replaced Side UUID"]) {
            uuids.push(event_data["Replaced Side UUID"]);
        }

        if (event_data["New Recipe ID"]) {
            uuids.push(event_data["New Recipe ID"]);
        }

        if (event_data["New Recipe UUID"]) {
            uuids.push(event_data["New Recipe UUID"]);
        }

        if (event_data["New Side ID"]) {
            uuids.push(event_data["New Side ID"]);
        }

        if (event_data["New Side UUID"]) {
            uuids.push(event_data["New Side UUID"]);
        }

        if (event_data["exclude_foods"]) {
            ["-", "+"].forEach((delta) => {
                let exclude_foods = [];

                if (typeof event_data["exclude_foods"][delta] === "string") {
                    exclude_foods = event_data["exclude_foods"][delta]
                        .split(",")
                        .map((u) => (u || "").trim())
                        .filter((v) => v);
                } else if (Array.isArray(event_data["exclude_foods"][delta])) {
                    exclude_foods = event_data["exclude_foods"][delta].slice(0);
                }

                exclude_foods.forEach((uuid) => {
                    if (!uuids.includes(uuid)) {
                        uuids.push(uuid);
                    }
                });
            });
        }
    });

    return new Promise((accept, reject) => {
        fetchDocumentsById(uuids).then((documents) => {
            const recipes = indexBy(
                    documents.filter((d) => d.type === "recipe"),
                    "uuid",
                ),
                plans = indexBy(
                    documents.filter((d) => d.type === "plan"),
                    "uuid",
                ),
                collections = indexBy(
                    documents.filter((d) => d.type === "collection"),
                    "uuid",
                ),
                foods = indexBy(
                    documents.filter((d) => d.type === "food"),
                    "uuid",
                );

            return accept({ recipes, plans, collections, foods, ...recipes, ...plans, ...collections, ...foods });
        });
    });
}

export function getPrescriptionProfileKey(rxs) {
    const order = ["all-day", "Breakfast", "Lunch", "Dinner", "Snack"];
    const rxsKey = {};
    (rxs || []).forEach((rx) => {
        const env = [];

        // Pick only the values we need out of the envelope
        Object.keys(rx.envelope).forEach((nutrNo) => {
            const range = rx.envelope[nutrNo];
            const newRange = {};

            if (typeof range.min === "number") {
                newRange.min = range.min;
            }

            if (typeof range.max === "number") {
                newRange.max = range.max;
            }

            env[nutrNo] = newRange;
        });

        rxsKey[rx.meal_type] = env;
    });

    return rxsKey;
}

export function generateProfileKey(profile) {

    const { preferences = {} } = profile;

    // FA stands for "fix array"
    const fa = (arr) => {
        arr = arr || [];
        arr = arr.filter((v) => v.trim()).sort((a, b) => a.localeCompare(b));

        arr = arr.filter((v, i) => i == arr.indexOf(v));

        return arr.slice(0, 50);
    };

    const family = profile?.family?.map((participant) => ({name: participant.name, portion: participant.portion, uuid: participant.uuid}));

    const profileKey = {
        avoid_cuisines: fa(preferences.avoid_cuisines),
        avoidances: fa(preferences.avoidances),
        avoid_keywords: preferences.avoid_keywords,
        daily_totals: preferences.daily_totals,
        equipment: fa(preferences.equipment),
        exclude_foods: fa(preferences.exclude_foods),
        family,
        limit_tags: fa(preferences.limit_tags),
        leftovers_enabled: preferences.leftovers_enabled ? true : false,
        max_leftover_days: preferences.max_leftover_days,
        meal_kit_providers: fa(preferences.meal_kit_providers),
        meal_types: preferences.meal_types,
        prefer_cuisines: fa(preferences.prefer_cuisines),
        prefer_milks: fa(preferences.prefer_milks),
        prefer_rices: fa(preferences.prefer_rices),
        skill_level: preferences.skill_level || '',
    };

    return profileKey;
}

export function profileKeyComparator(profile, profile_key) {
    let isParticipantsChanged = false;
    let isProfileChanged = false;
    let reasons = [];

    if (!profile_key || !profile) {
        console.log({f: 'profileKeyComparator rejected', profile, profile_key});
        return { isParticipantsChanged, isProfileChanged, reasons: ['missing profile or profile key'] };
    }

    const keysToCmp = [
        'avoid_cuisines', 'avoidances', 'avoid_keywords', 'daily_totals', 'equipment', 'exclude_foods', 'family', 'limit_tags', 'leftovers_enabled',
        'prefer_cuisines', 'prefer_milks', 'prefer_rices', 'meal_kit_providers', 'meal_types', 'max_leftover_days', 'skill_level'
    ];

    keysToCmp.forEach(key => {

        // if both are nullish then skip
        if (_.isNil(profile.preferences[key]) && _.isNil(profile_key[key])) {
            return;
        }

        if (!_.isEqual((profile.preferences[key]), (profile_key[key]))) {
            isProfileChanged = true;
            console.log({
                f: 'profileKeyComparator',
                isProfileChanged,
                key,
                value_from_profile: (profile.preferences[key]),
                value_from_key: (profile_key[key]),
            });
        }
    });

    return { isParticipantsChanged, isProfileChanged };
}
