'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import store from 'store';

import Dropdown from '../Widgets/Dropdown.react';
import PatientSelect from '../Widgets/PatientSelect.react';
import Working from '../Patients/Editor/Working.react';
import PrescriptionMismatch from '../Plans/Editor/PrescriptionMismatch.react';
import PlanToPdfButton from '../Plans/Editor/PlanToPdfButton.react';
import CollectionToPdfButton from '../../../components/Collections/Editor/CollectionToPdfButton.react';
import PlanCard from '../../../components/Dashboard/FeaturedPlans/PlanCard.react';
import CollectionCard from '../../../components/Dashboard/FeaturedPlans/CollectionCard.react';
import RecipeCard from '../../../components/Dashboard/FeaturedPlans/RecipeCard.react';
import AddRecipient from './RecommendModal/AddRecipient.react';
import ImgResized from '../../../components/Widgets/ImgResized.react';
import Alert from '../../../components/Widgets/Alert/Alert.react';

import { createNewDocument } from '../../../utils/Content';
import { deleteVirtualPlan } from '../../../utils/Plans';
import { getConfig } from '../../../utils/Env';
import { inquire } from '../../../utils/Enforcer';

import AuthStore from '../../../stores/AuthStore';
import UserStore from '../../../stores/UserStore';
import Analytics from '../../../utils/Analytics';

import './RecommendModal.scss';
import modalStyles from '../../../jsx-styles/modals';

export default class RecommendModal extends Component {
    static propTypes = {
        patient: PropTypes.object,
        patients: PropTypes.array,
        onRecommendPlan: PropTypes.func,

        plan: PropTypes.object,
        collection: PropTypes.object,
        recipe: PropTypes.object,

        error: PropTypes.string,
        closeModal: PropTypes.func,
    };

    static defaultProps = {
        patients: [],
    };

    static contextTypes = {
        onChangePatient: PropTypes.func,
        replaceMealPlan: PropTypes.func,
        showUpgradeForm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            user,
            patients: [],
            results: [],
            addingRecipients: false,
            message: '',
            working: true,
            sending: false,
            sent: false,
            recipientMode: 'list',

            first_name: '',
            last_name: '',
            email: '',
            email2: '',

            hasPatients: false,
            canRecommend: false,
        };
    }

    componentDidMount = () => {
        const { patient } = this.props;
        Analytics.startRecommendation();
        this.setState({patients: patient ? [patient] : []}, this.syncAssets);
    }

    syncAssets = async () => {
        const { patients } = this.state;
        const { plan, collection, recipe } = this.props;

        let feature = (plan       && 'recommend_plan') ||
                      (collection && 'recommend_collection') ||
                      (recipe     && 'recommend_recipe');

        // If there are no recipients yet, assume that this user has permission. If they don't actually,
        // then once a recipient is added, the inquiry will determine for sure if they can or not
        if (!(patients && patients.length)) {
            this.setState({canRecommend: true});
            return;
        }

        // Construct one inquiry for each patient we're going to be recommending to.
        const inquiries = patients.map(patient => ({
            action: 'use',
            resource: feature,
            environment: patient.uuid
        }));

        const decisions = await inquire(inquiries)

        if (!decisions) {
            console.log('no decisions returned');
            return;
        }

        let canRecommend = true;

        decisions.forEach(d => {
            if (d.decision !== 'permit') {
                canRecommend = false;
            }
        })

        this.setState({canRecommend});
    }

    removePatient = (patient) => {
        const { patients } = this.state;

        patients.splice(patients.indexOf(patient), 1);

        this.setState({patients}, this.syncAssets);
    }

    sendRecommendation = (plan, patients, message) => {
        const { patient, closeModal, collection, recipe } = this.props;
        const { onChangePatient } = this.context;
        const { user } = this.state;

        const patientUuids = [];
        patients.forEach(p => patientUuids.push(p.uuid));

        const body = {
            patients: patientUuids,
            plan_uuid: plan && plan.uuid,
            collection_uuid: collection && collection.uuid,
            recipe_uuid: recipe && recipe.uuid,
            message,
        };

        this.setState({sending: true});

        Analytics.sendRecommendation();

        // Now we're sure that the uuid exists in the database,
        AuthStore.fetch(getConfig('users_api') + user.links.practice.href + '/recommend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; schema=recommendation/1'
            },
            body: JSON.stringify(body)
        }).then(
            async response => {

                if (patient && onChangePatient) {
                    patient.recommendations = patient.recommendations || [];

                    if (response.elements && response.elements[0] && response.elements[0].contents && response.elements[0].contents[0]) {
                        patient.recommendations.push(response.elements[0].contents[0]);
                    }

                    // Server doesn't respond with new invite_sent value,
                    // but if the patient hasn't accepted the invite yet, we know that it did it.
                    if (!patient.invite_accepted) {
                        patient.invite_sent = moment().format('YYYY-MM-DD HH:mm:ss');
                    }

                    onChangePatient(patient);
                }

                let job = await AuthStore.fetch(getConfig("users_api") + "/jobs/" + response?.message);
                let governor = 500;

                while (job?.status !== "completed" && job?.status !== "failed" && governor-- > 0) {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    job = await AuthStore.fetch(getConfig("users_api") + "/jobs/" + response?.message);
                }

                if (job?.status !== "completed") {
                    throw Error("Failed to send recommendations");
                }

                this.setState({working: false, sending: false, sent: true, error: null});

            },
            error => {
                this.setState({
                    working: false,
                    sending: false,
                    error: (error && error.message) || error || 'unknown error occured',
                });
            }
        );
    }

    createPatientThenSendRecommendation = () => {
        const { profile, plan } = this.props;
        const { message, user } = this.state;
        let { first_name, last_name, email, email2 } = this.state;

        first_name = first_name.trim();
        last_name = last_name.trim();
        email = email.trim();
        email2 = email2.trim();

        if (!first_name || !last_name) {
            this.setState({error: "Please enter first and last name"});
            return;
        }

        if (!email) {
            this.setState({error: "Please enter an email address"});
            return;
        }

        if (email != email2) {
            this.setState({error: "Emails do not match"});
            return;
        }

        const { conditions = [], prescriptions = [], family = [], birthdate, gender, weight_kg, height_cm,
                preferences = {diets: [], avoidances: [], breakfasts: 3, lunches: 3, dinners: 3, snacks: 3},
                target_energy_kcal, due_date, fetus_count, activity_level, goal_weight_kg, weekly_goal_kcal,
                units_mode, completed } = profile;

        // Create a new patient using the profile stored in the Alternate User
        // Once that's done, send recommendation
        const newPatient = {
            energy_method: 'mifflin',
            practice_type: user.practice_type,
            units_mode,
            conditions,
            prescriptions,
            preferences,
            family,
            daily_newsletter: true,
            daily_newsletter_time: '05:00:00',
            weekly_newsletter: true,
            following: true,
            first_name,
            last_name,
            email,
            birthdate, gender, weight_kg, height_cm,
            target_energy_kcal, due_date, fetus_count, activity_level, goal_weight_kg, weekly_goal_kcal,
            units_mode, completed,
        };

        this.setState({sending: true});

        const links = UserStore.getLinks();

        AuthStore.fetch(links.patients, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; schema=patient/1',
            },
            body: JSON.stringify(newPatient),
        }).then(
            response => {
                this.setState({patients: [response], createdPatient: response}, this.onRecommendPlan);
            },
            error => {
                this.setState({
                    sending: false,
                    error: (error && error.message)
                         ? ((error.field ? (error.field + ': ') : '') + error.message)
                         : (error || 'unknown error occured')
                });
            }
        );
    }



    onRecommendCollectionOrRecipe = () => {
        const { message, patients } = this.state;

        if (!(patients && patients.length > 0)) {
            this.setState({error: 'Please add at least one recipient'});
            return;
        }

        this.sendRecommendation(null, patients, message);
    }

    onRecommendPlan = () => {
        const { plan, dirty } = this.props;
        const { message, patients } = this.state;
        const { replaceMealPlan } = this.context;

        let protection = 'public';

        // Do we need to set this meal plan to private mode?
        // If any of the patients this is being recommended to are blocked from seeing nutrition,
        // then the meal plan has to be set to private mode.
        patients.forEach(patient => {
            if (patient && patient.hide_nutrition) {
                protection = 'private';
            }
        })

        this.setState({sending: true});

        // First, we need to determine if we should create this meal plan in the database.
        // If the meal plan is generated OR it has been tweaked, we need to store it first.
        if (!(plan.links && plan.links.self) || dirty || protection != 'public') {
            let { uuid, links, created, type, merchant, status, items, ...rest } = plan;

            items = items.map(({logged_grams, logged_milliliters, ...rest}) => {
                const item = rest;

                if (logged_grams) item.logged_grams = logged_grams;
                if (logged_milliliters) item.logged_milliliters = logged_milliliters;

                return item;
            });

            // Create the new document
            createNewDocument('plan', {protection, items, ...rest}, 'plan/1').then(
                response => {
                    // If we have a replaceMealPlan context function, let's inform our ancestor
                    // of the change
                    replaceMealPlan && replaceMealPlan(plan, response);

                    // deleteVirtualPlan(plan.uuid, response.uuid);
                    store.remove('menu-edited-' + plan.uuid);

                    // Meal plan is no longer dirty, unset the dirty flag.
                    this.setState({plan: response, dirty: false});

                    // Then send it to the patients
                    this.sendRecommendation(response, patients, message);
                },
                error => {
                    this.setState({
                        sending: false,
                        error: (error && error.message)
                             ? ((error.field ? (error.field + ': ') : '') + error.message)
                             : (error || 'unknown error occured')})
                }
            );
        } else {
            // Send the recommendation as is.
            this.setState({plan}, () => this.sendRecommendation(plan, patients, message));
        }
    }

    onCloseAfterDone = () => {
        const { closeModal } = this.props;

        closeModal && closeModal();
    }

    onSearchPatientChange = (patientSearchValue) => {;
        this.setState({patientSearchValue});
    }

    toggleRecipientMode = () => {
        const { recipientMode } = this.state;

        if (recipientMode === 'create') {
            this.setState({recipientMode: 'list'});
        } else {
            this.setState({recipientMode: 'create'});
        }
    }

    onAddPatient = (patient) => {
        const { plan } = this.props;
        const { patients } = this.state;

        // Already exists in the list, search by UUID instead of object
        if (patients.filter(p => p.uuid === patient.uuid).length > 0) {
            return;
        }

        patients.push(patient);

        this.setState({patients}, this.syncAssets);
    }

    rendertoPdfButton = (user) => {
        const { collection } = this.props;
        const { patients, plan } = this.state;
        const canPrintPlan = Boolean(user?.capabilities?.print_plan);
        const canPrintCollection = Boolean(user?.capabilities?.print_collection);

        if(collection && canPrintCollection){
            return (
                <CollectionToPdfButton classes={["el-modal-ok-btn"]} button={<> PRINT OR SAVE PDF</>}
                    collection={collection} patient={patients[0]} />
            )
        } else if(plan){
            const icon = canPrintPlan ? "icon-print" : "icon-lock";
            return (
                <PlanToPdfButton classes={["el-modal-ok-btn"]} button={<> PRINT OR SAVE PDF</>}
                    plan={plan} patient={patients[0]} />
            )
        }
    }

    onClickSendRecommendation = () => {
        const { plan, collection, recipe } = this.props;
        const { canRecommend } = this.state;
        const { showUpgradeForm } = this.context;

        if (!canRecommend) {
            let feature = (plan       && 'recommend_plan') ||
                          (collection && 'recommend_collection') ||
                          (recipe     && 'recommend_recipe');

            return showUpgradeForm({feature});
        }

        if (plan) {
            return this.onRecommendPlan();
        }

        if (collection || recipe) {
            return this.onRecommendCollectionOrRecipe();
        }
    }

    renderShareForm = () => {
        const { collection, plan, recipe, closeModal } = this.props;
        const { user, error, message, patients, sending, sent, canRecommend } = this.state;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className={"el-modal el-modal2 recommend-modal" }
                contentLabel="Edit Patient"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container recommend-modal-container">
                    {!sending ?
                        <header>
                            <button className="el-modal-close-x" onClick={() => closeModal(sent)}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>
                    : null}
                    {error ? <Alert type="error" description={error} /> : null}
                    {sent && !sending ?
                        <div className="el-modal-body-container el-modal2-body-container recommendation-sent">
                            {collection ? <h2>The recipe collection has been sent!</h2> : null}
                            {recipe ? <h2>The recipe has been sent!</h2> : null}
                            {plan ? <h2>Your recommendation has been sent!</h2> : null}
                            {patients && patients.length > 0 ?
                                <span>
                                    {!patients[0].invite_accepted && collection ?
                                        <h3>An invitation to EatLove has been included with this recipe collection</h3>
                                    : null}
                                    {!patients[0].invite_accepted && recipe ?
                                        <h3>An invitation to EatLove has been included with this recipe</h3>
                                    : null}
                                    {!patients[0].invite_accepted && plan ?
                                        <h3>An invitation to EatLove has been included with this meal plan</h3>
                                    : null}

                                </span>
                            : null}
                        </div>
                    : null}
                    {sent && !sending ?
                            <footer>
                                <button className="el-modal-cancel-btn" onClick={this.onCloseAfterDone}>Close</button>
                                {this.rendertoPdfButton(user)}
                            </footer>
                    : null}
                        
                    {!sending && !sent ?
                        <div className="el-modal-body-container el-modal2-body-container">
                           {collection ? <div className="plan-card-container"> <CollectionCard collection={collection} /> </div> : null}
                           {recipe ? <div className="plan-card-container"> <RecipeCard recipe={recipe} /> </div> : null}
                           {plan ? <div className="plan-card-container"> <PlanCard plan={plan} /></div> : null}
                            <div className="plan-recipients-container">
                                <h2>Recommend To...</h2>
                                <div className="recipients-added">
                                    {patients.length >= 1 && patients.map((patient, index)=>
                                        <span key={index}>
                                            <div>
                                                {patient.image ? <ImgResized width={45} height={45} src={patient.image} className="avatar" /> : null}
                                                {!patient.image ? <i className={patient.gender === 'male' ? 'icon-male2' : 'icon-female2'} />: null}
                                                <p className="recipient-label" key={index}>{patient.first_name} {patient.last_name}</p>
                                            </div>
                                            <span className="dot"><i className="icon-close-x x-symbol" onClick={() => this.removePatient(patient)} /></span>
                                        </span>
                                     )}
                                </div>
                                <button className="add-recipient-btn" onClick={() => this.toggleAddingRecipients()}>
                                    <i className="icon-plus-thin" /> Add Recipient
                                </button>
                            </div>
                            <textarea className="message" value={message}
                                placeholder="Enter optional message to recipient(s)"
                                onChange={ev => this.setState({message: ev.target.value})} />
                        </div>
                    : null}
                    {!sending && !sent ?
                        <footer>
                            <button className="el-modal-cancel-btn" onClick={this.onCloseAfterDone}>Cancel</button>
                            <button className="el-modal-ok-btn" onClick={this.onClickSendRecommendation}>
                                {`Send (${patients.length})`}
                                {!canRecommend ? <i className="icon-lock" /> : null}
                            </button>
                        </footer>
                    : null}

                    {sending ?
                        <div className="el-modal-body-container el-modal2-body-container recommendation-sending">
                            <Working title="Sending Recommendation" message="Your recommendation is being sent." />
                        </div>
                    : null}
                </div>
            </Modal>
        )
    }

    toggleAddingRecipients = (selectedRecipients = []) => {
        const { patients } = this.state;
        let newPatients = patients
        if(selectedRecipients.length){
            newPatients = newPatients.concat(selectedRecipients)
        }
        this.setState({addingRecipients: !this.state.addingRecipients, patients: newPatients}, this.syncAssets)
    }

    prependNewPatient = (patient) => {
        this.setState(prevState => ({
          results: [patient, ...prevState.results]
        }), this.syncAssets)
    }

    executeSearch = (searchTerms = '') => {
        const { practice } = this.state.user;

        const url = getConfig('users_api') + practice.links.patients.href;
        const query = {
            terms: searchTerms,
            embed: [],
            size: 100
        };

        this.setState({loading: true});

        AuthStore.fetch({url, query}).then(
            data => this.setState({
                 results: data.elements.filter((element)=>{ return this.state.patients.find(p => p.uuid === element.uuid) == null}),
                 loading: false
             }),
            error => this.setState({loading: false}),
        );
    }

    render() {
        const { loading, addingRecipients, creatingPatient, results } = this.state;

        return (
            <div>
                {addingRecipients ?
                    <AddRecipient closeModal={this.toggleAddingRecipients}
                        renderRecipientsForm={this.renderRecipientsForm}
                        executeSearch={this.executeSearch}
                        prependNewPatient={this.prependNewPatient}
                        results={results}
                        loading={loading}/>
                : this.renderShareForm()}
           </div>
        );
    }
}
