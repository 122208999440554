
'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import assign from 'object-assign';
import AuthStore from './AuthStore';
import NutritionPatternConstants from '../constants/NutritionPatternConstants';
import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';

let _store = {
    nutritionPatterns: [],
    loaded: false,
    loading: false,
    preferences : {}
};

async function loadNutritionPatterns() {
    const nutritionPatterns = await AuthStore.fetch(getConfig('users_api') + '/nutrition-patterns');
    _store.nutritionPatterns = nutritionPatterns.elements;
}

async function resolveMealTypes(patient, pattern, calculate_implicit = true) {

    if (!pattern) {
        const nutritionPatterns = await AuthStore.fetch(getConfig('users_api') + '/nutrition-patterns');
        _store.nutritionPatterns = nutritionPatterns.elements;

        if (!patient.conditions) {
            return;
        }

        pattern = nutritionPatterns?.elements.find((pattern) => pattern.uuid === patient.conditions[0].uuid);
    }

    // If we can't resolve this profile, don't bother.
    if (!pattern.links.resolve) {
        return;
    }

    const query = {
        gender: patient.gender,
        birthdate: patient.birthdate,
        weight_kg: patient.weight_kg,
        target_energy_kcal: patient.target_energy_kcal,
        calculate_implicit: calculate_implicit,
    };

    const response = await AuthStore.fetch({url: getConfig('users_api') + pattern.links.resolve.href, query});

    if (!response.preferences?.length) {
        return;
    }

    _store.preferences = response.preferences[0];
}

var NutritionPatternStore = assign({}, EventEmitter.prototype, {
    getNutritionPatterns: function() {
        return _store.nutritionPatterns || [];
    },

    getPreferences: function() {
        return _store.preferences || {};
    },

    isLoaded: function() {
        return _store.loaded || false;
    },

    isLoading: function() {
        return _store.loading || false;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
});

export default NutritionPatternStore;

AppDispatcher.register((payload) => {
    const { actionType } = payload.action;

    switch (actionType) {
        case NutritionPatternConstants.NUTRITION_PATTERN_LOAD:
            if (_store.loaded || _store.loading) {
                return;
            }
            _store.loading = true;
            NutritionPatternStore.emitChange();
            loadNutritionPatterns().then(() => {
                _store.loading = false;
                _store.loaded = true;
                NutritionPatternStore.emitChange();
            });
            break;

        case NutritionPatternConstants.NUTRITION_PATTERN_RELOAD:
            _store.loading = true;
            NutritionPatternStore.emitChange();
            loadNutritionPatterns().then(() => {
                _store.loading = false;
                _store.loaded = true;
                NutritionPatternStore.emitChange();
            });
            break;
        case NutritionPatternConstants.RESOLVE_PATTERN:
            const patient = payload.action.patient;
            const pattern = payload.action.pattern;
            _store.loading = true;
            NutritionPatternStore.emitChange();
            resolveMealTypes(patient, pattern).then(() => {
                _store.loading = false;
                _store.loaded = true;
                NutritionPatternStore.emitChange();
            });
            break;
    }
});
