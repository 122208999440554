'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddNutrients from './Prescription/AddNutrients.react';
import RemoveNutrients from './Prescription/RemoveNutrients.react';
import EnvelopeEditor from './Prescription/EnvelopeEditor.react';
import Select from '../../Widgets/Select.react';
import MyTabs from '../../../../components/Widgets/Tabs.react';
import UserStore from '../../../../stores/UserStore';
import allNutrients from '../../../../tables/nutrients';
import allRecommendations from '../../../../tables/recommendations';
import Popup from '../../../../components/Widgets/Popup.react';
import { getDemographic } from '../../../../utils/Nutrition';
import { roundForHumans } from '../../../../utils/Math';
import NutritionPatternActions from '../../../../actions/NutritionPatternActions';
import './Prescription.scss';

export default class Prescription extends Component {
    static propTypes = {
        isMealPlanWizard: PropTypes.bool,
    };

    static defaultProps = {
        isMealPlanWizard: false
    };

    static contextTypes = {
        user: PropTypes.object,
        showUpgradeForm: PropTypes.func
    };

    constructor(props) {
        super(props);

        const { patient = {} } = props;
        const { preferences = {}, next_update } = patient;
        const allDay = { envelope: patient.preferences.daily_totals };

        const expiresIn = next_update ? moment(next_update).diff(moment().format('YYYY-MM-DD'), 'days') : 0;

        this.state = {
            patient,
            preferences,
            allDay,
            editMode: true,
            error: '',
            nutrientErrors: [],
            dirty: false,
            expiresIn,
            next_update: patient.next_update,
            addedMealTypes: []
        };
    }

    componentDidMount = () => {
        const preferences = { ...this.state.preferences };
        preferences.meal_types = preferences.meal_types.map((mealType) => {
            return {
                ...mealType,
                envelope: this.syncWithDailyTotals(mealType.envelope) || {},
            };
        });
        this.setState({preferences});
        this.calculateDists();
    }

    calculateDists = () => {
        const { allDay, preferences } = this.state;

        const dist = {};

        preferences.meal_types.filter(p => p.envelope != null).forEach(mealRx => {
            if (!dist[mealRx.name]) {
                dist[mealRx.name] = {};
            }

            Object.keys(mealRx.envelope).forEach(nutrNo => {
                const range = mealRx.envelope[nutrNo];
                const allDayRange = allDay && allDay.envelope && allDay.envelope[nutrNo];

                if (!dist[mealRx.name][nutrNo]) {
                    dist[mealRx.name][nutrNo] = {}
                }

                if (!range || !allDayRange) {
                    return;
                }

                if (range.min && allDayRange.min) {
                    dist[mealRx.name][nutrNo].dist_min = Math.round(range.min / allDayRange.min * 100) / 100;
                }

                if (range.max && allDayRange.max) {
                    dist[mealRx.name][nutrNo].dist_max = Math.round(range.max / allDayRange.max * 100) / 100;
                }
            });
        });

        this.setState({dist});
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validate = () => {
        const { expiresIn } = this.state;

        // If we have a next update, it must be in the future
        const { practice_type } = UserStore.getUser();
        if (expiresIn && practice_type === 'dietetics') {
            if (expiresIn < 0) {
                this.setState({error: 'expires-in'});
                return 'Prescription is expired! Please update the prescription expiration.';
            }
        }

        this.setState({nutrientErrors: [], error: null});
        return true;
    }

    mutate = (patient) => {
        const { allDay, preferences, expiresIn } = this.state;

        patient.next_update = expiresIn ? moment().add(expiresIn, 'days').format('YYYY-MM-DD') : '';
        patient.preferences.daily_totals = allDay.envelope
        patient.preferences.meal_types = preferences.meal_types;

        patient.completed = patient.completed || [];
        if (!patient.completed.includes('nutrition')) {
            patient.completed.push('nutrition');
        }

        return patient;
    }

    onChangeAllDayEnvelope = (envelope, nutrNo = '') => {
        const { allDay, preferences } = this.state;

        allDay.envelope = envelope;

        preferences.meal_types
            .filter(p => p.envelope != null)
            .forEach((mealRx) => mealRx = this.calculateDistribution(mealRx, allDay, nutrNo));

        this.setState({allDay, preferences, dirty: true});
    }

    calculateDistribution = (mealRx, allDay, nutrNo) => {
        const { dist } = this.state;

        if (!dist[mealRx.name] || !dist[mealRx.name][nutrNo]) {
            return mealRx;
        }

        const nutrNoDist = dist[mealRx.name][nutrNo];

        if (nutrNoDist.dist_min) {
            mealRx.envelope[nutrNo].min = roundForHumans(allDay.envelope[nutrNo].min * nutrNoDist.dist_min);
        }

        if (nutrNoDist.dist_max) {
            mealRx.envelope[nutrNo].max = roundForHumans(allDay.envelope[nutrNo].max * nutrNoDist.dist_max);
        }

        return mealRx;
    }

    calculateDistributionByMealType = (mealRx, allDay) => {
        Object.keys(mealRx.envelope).forEach(nutrNo => {
            mealRx = this.calculateDistribution(mealRx, allDay, nutrNo);
        });

        return mealRx;
    }

    getDistribution = () => {
        Math.round(range.max / allDayRange.max * 100) / 100
    }

    onAddMealType = async (mealType) => {
        const { allDay, preferences, addedMealTypes } = this.state;

        mealType.envelope = this.syncWithDailyTotals(mealType.envelope || {});
        mealType = this.calculateDistributionByMealType(mealType, allDay);
        
        const newMealTypes = addedMealTypes.concat(mealType);

        this.setState({preferences, addedMealTypes: newMealTypes, dirty: true});
    }

    onRemoveMealType = (mealType) => {
        const { preferences, addedMealTypes } = this.state;
        const mealTypes = preferences.meal_types;
        mealTypes.filter(p => p.name == mealType.name).forEach((mealType) => mealType.envelope = null);
        const updatedPreferences = {...preferences, meal_types: mealTypes};
        const updatedAddedMealTypes = addedMealTypes.filter(p => p.name != mealType.name);

        this.setState({preferences: updatedPreferences, addedMealTypes: updatedAddedMealTypes, dirty: true});
    }

    syncWithDailyTotals = (envelope) => {
        const { allDay } = this.state;

        if (envelope) {
            for (const nutrNo in envelope) {
                if (!(nutrNo in allDay.envelope)) {
                    delete envelope[nutrNo];
                }
            }

            for (const nutrNo in allDay.envelope) {
                if (!(nutrNo in envelope)) {
                    envelope[nutrNo] = {};
                }
            }
        }

        return envelope;
    }

    onChangeMealEnvelope = (mealType, newEnvelope, nutrNo = '') => {
        const { preferences } = this.state;

        newEnvelope =this.syncWithDailyTotals(newEnvelope);

        preferences.meal_types
            .filter(p => p.name == mealType.name)
            .forEach(p => { p.envelope = newEnvelope; });

        this.setState({preferences, dirty: true});
    }

    onAddNutrients = async (nutrNos) => {
        const { allDay, preferences } = this.state;
        const { patient } = this.props;
        const { target_energy_kcal } = patient;

        // Determine the all-day DRI value (if available)
        const demographic = getDemographic(patient);
        const dri = {
            ...allRecommendations['all-ages'],
            ...allRecommendations[demographic]
        };

        const macros = {
            '203': {per_min: 0.15, per_max: 0.30},
            '204': {per_min: 0.25, per_max: 0.35},
            '205': {per_min: 0.45, per_max: 0.55},
        };

        let kcal_min = (allDay && allDay.envelope['208'] && allDay.envelope['208'].min) ||
                       Math.round((target_energy_kcal - 100) / 5) * 5,
            kcal_max = (allDay && allDay.envelope['208'] && allDay.envelope['208'].max) ||
                       Math.round((target_energy_kcal + 100) / 5) * 5;

        nutrNos.forEach(nutrNo => {
            const range = {};
            const nutrient = allNutrients[nutrNo];

            if (!nutrient) {
                return;
            }

            if (nutrNo === '208') {
                range.min = kcal_min;
                range.max = kcal_max;
            } else if (macros[nutrNo]) {
                range.min = roundForHumans(kcal_min * macros[nutrNo].per_min / allNutrients[nutrNo].calories_per_gram);
                range.max = roundForHumans(kcal_max * macros[nutrNo].per_max / allNutrients[nutrNo].calories_per_gram);
                range.implicit = {min: macros[nutrNo].per_min, max: macros[nutrNo].per_max};
            } else if (dri[nutrNo]) {
                if (nutrient.envelope === 'above-80p') {
                    range.min = dri[nutrNo];
                } else if (nutrient.envelope === 'below-120p') {
                    range.max = dri[nutrNo];
                } else if (nutrient.envelope === 'within-20p') {
                    range.min = roundForHumans(dri[nutrNo] * 0.8);
                    range.max = roundForHumans(dri[nutrNo] * 1.2);
                }
            }

            allDay.envelope[nutrNo] = range;
        });

        const resolvedPreferences = await NutritionPatternActions.resolve(patient);
        const resolvedMealTypes = resolvedPreferences.meal_types;

        // If we have per-meal prescriptions, add the nutrient to those as well, populating the values and dist_min and dist_max's
        preferences.meal_types.filter(v => v).forEach(mt => {
            nutrNos.forEach(nutrNo => {
                if (mt.envelope) {
                    const resolvedEnvelope = resolvedMealTypes.filter(p => p.name == mt.name)[0].envelope;
                    mt.envelope[nutrNo] = resolvedEnvelope && resolvedEnvelope[nutrNo] ? resolvedEnvelope[nutrNo] : {};
                }
            });

            mt.envelope = this.syncWithDailyTotals(mt.envelope);
        });

        this.setState({allDay, preferences, dirty: true});
    }

    onRemoveNutrients = (nutrNos) => {
        const { allDay, preferences } = this.state;

        nutrNos.forEach(nutrNo => {
            if (allDay.envelope[nutrNo]) {
                delete allDay.envelope[nutrNo];
            }
        });

        preferences.meal_types.forEach((p) => { p.envelope = this.syncWithDailyTotals(p.envelope); });

        this.setState({preferences, dirty: true});
    }

    toggleEditMode = () => {
        const { editMode } = this.state;
        this.setState({editMode: !editMode});
        return !editMode;
    }

    isEditMode = () => {
        return this.state.editMode;
    }

    setRxExpires = (expiresIn) => {
        this.setState({expiresIn});
    }

    renderFooter = () => {
        const { isMealPlanWizard } = this.props;
        const { allDay, expiresIn, error } = this.state;
        const { user, showUpgradeForm } = this.context;
        const { capabilities = {} } = user || {};

        let canExpire = capabilities.edit_micros;

        let expiresInOpts = [
            {label: 'no review needed', value: 0},
            {label: '1 week', value: 7},
            {label: '2 weeks', value: 7 * 2},
            {label: '3 weeks', value: 7 * 3},
            {label: '4 weeks', value: 7 * 4},
            {label: '5 weeks', value: 7 * 5},
            {label: '6 weeks', value: 7 * 6},
            {label: '8 weeks', value: 7 * 8},
            {label: '10 weeks', value: 7 * 10},
            {label: '12 weeks', value: 7 * 12},
            {label: '24 weeks', value: 7 * 24},
            {label: '52 weeks', value: 7 * 52},
        ];

        // Do we have a next_update value already? Make sure we can select it.
        if (expiresIn != 0 && !expiresInOpts.find(o => o.value == expiresIn)) {
            if (expiresIn < 0) {
                const expiredAgo = Math.abs(expiresIn);
                expiresInOpts.push({
                    label: 'EXPIRED ' + expiredAgo + (expiredAgo == 1 ? ' day ago' : ' days ago'),
                    value: expiresIn,
                });
            } else if (!expiresInOpts.filter(o => o.value == expiresIn)[0]) {
                expiresInOpts.push({
                    label: expiresIn + (expiresIn == 1 ? ' day' : ' days'),
                    value: expiresIn,
                });
            }

            expiresInOpts = expiresInOpts.sort((a, b) => a.value - b.value);
        }

        return (
            <footer>
                {user.practice_type !== 'fitness' ?
                    (<div>
                        <AddNutrients onAddNutrients={this.onAddNutrients}
                            currentNutrients={Object.keys(allDay?.envelope)} />
                        <RemoveNutrients onRemoveNutrients={this.onRemoveNutrients}
                            currentNutrients={Object.keys(allDay?.envelope)} />
                    </div>)
                : null}

                {user.practice_type !== 'fitness' && !isMealPlanWizard ?
                    (<div className="with-label expires-in" data-error={error === 'expires-in'}>
                        <label>Review Prescription in:</label>
                        <Select value={expiresIn}
                            placeholder="Choose expiration..."
                            onChange={canExpire ? expiresIn => this.setRxExpires(expiresIn) : () => showUpgradeForm({feature: 'edit_micros'}) }
                            options={expiresInOpts}>
                        </Select>
                    </div>)
                : null}

            </footer>
        )
    }

    render() {
        const { patient } = this.props;
        const { editMode, allDay, nutrientErrors, preferences, addedMealTypes } = this.state;
        const { practice_type } = UserStore.getUser();

        const canChangePerMeal = practice_type === 'fitness' ? false : true;

        let mealTypesWithEnvelope = preferences.meal_types.filter(
            (mealType) =>
                mealType.envelope &&
                Object.values(mealType.envelope).some(
                    (obj) => "min" in obj || "max" in obj,
                ),
        );
        const unmodifiedAddedMealTypes = addedMealTypes.filter((mealType) => !mealTypesWithEnvelope.some((mt) => mt.name === mealType.name));
        mealTypesWithEnvelope = mealTypesWithEnvelope.concat(unmodifiedAddedMealTypes);
        const mealTypesWithoutEnvelope = preferences.meal_types.filter(
            (mealType) =>
                !mealTypesWithEnvelope.some((mt) => {
                    return mt.name == mealType.name;
                }),
        );

        return (
            <div className="edit-nutrition-prescription patient-form">
                <div className="envelope-editors">
                    <MyTabs className="generic-tabs" defaultTab="Daily Totals"
                        buttonAddTab={
                            mealTypesWithoutEnvelope.length ? (
                                <Popup positionClassName="el-popup-top-center"
                                    className="el-toolbar-popup"
                                    dropdownBtnClass="add-tab-btn"
                                    button={<i className="icon-plus-thick" />}>
                                    <header>
                                        <h5>Meal Types</h5>
                                    </header>

                                    {mealTypesWithoutEnvelope.map(p => (
                                        <section>
                                            <button className={"el-chiqlet-toggle-btn el-mealtype-option"}
                                                onClick={() => this.onAddMealType(p)}>
                                                    {p.name}
                                            </button>
                                        </section>
                                    ))}
                                </Popup>
                            ) : null
                        }>
                        <section data-title="Daily Totals">
                            <div>
                                <EnvelopeEditor showPercents={true}
                                    data-edit-mode={editMode}
                                    patient={patient}
                                    envelope={allDay.envelope}
                                    nutrientErrors={nutrientErrors}
                                    onChangeEnvelope={this.onChangeAllDayEnvelope} />
                                {this.renderFooter()}
                             </div>
                        </section>

                        {canChangePerMeal && mealTypesWithEnvelope.length ?
                            mealTypesWithEnvelope.map(p => (
                                <section data-title={p.name} key={p.name} onClickRemove={() => this.onRemoveMealType(p)}>
                                    {false ? <p className="explainer">Snack prescription applies to all snacks combined for the day and can only be limited to maximums.</p> : null}
                                    <div>
                                        <EnvelopeEditor showPercents={true}
                                            mealType={p.main_dish}
                                            data-edit-mode={editMode}
                                            patient={patient}
                                            envelope={p.envelope || {}}
                                            nutrientErrors={nutrientErrors}
                                            onChangeEnvelope={(envelope, nutrNo) => this.onChangeMealEnvelope(p, envelope, nutrNo)} />
                                        {this.renderFooter()}
                                    </div>
                                </section>
                            )) : null}
                    </MyTabs>
                </div>
            </div>
        );
    }
}
