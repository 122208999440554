'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import NutritionPatternConstants from '../constants/NutritionPatternConstants';

const NutritionPatternActions = {
    
    load: () => {
        AppDispatcher.handleViewAction({
            actionType: NutritionPatternConstants.NUTRITION_PATTERN_LOAD
        });
    },

    reload: () => {
        AppDispatcher.handleViewAction({
            actionType: NutritionPatternConstants.NUTRITION_PATTERN_RELOAD
        });
    },

    resolve: (patient, pattern) => {
        AppDispatcher.handleViewAction({
            actionType: NutritionPatternConstants.RESOLVE_PATTERN,
            patient,
            pattern
        });
    }
};

export default NutritionPatternActions;
