const isSameArray = (a, b) => {
    return a.length === b.length && a.every((v, i) => v === b[i]);
};

export const isSameValue = (a, b) => {
    if (Array.isArray(a)) {
        return isSameArray(a, b);
    }

    if (a instanceof Date) {
        return new Date(a).getTime() == new Date(b).getTime();
    }

    return a == b;
};

export const isValidURL = (string) => {
    try {
        new URL(string);
        return true;
    } catch (e) {
        return false;
    }
}

export const removeDuplicatesByAttributes = (arr, attributes) => {
    const uniqueObjects = {};
    const result = [];

    arr && arr.forEach(item => {
      const groupKey = attributes.map(attr => item[attr]).join('_');
      if (!uniqueObjects[groupKey]) {
        uniqueObjects[groupKey] = true;
        result.push(item);
      }
    });

    return result;
}

export const countElementsByAttribute = (arr, keys, removeDuplicates = false, keyName = null) => {
    const countMap = [];

    if (removeDuplicates) {
        arr = removeDuplicatesByAttributes(arr, keys)
    }

    arr && arr.forEach((item) => {
        const keyValue = JSON.stringify(keyName ? item[keyName] : item[keys[0]]);
        countMap[keyValue] = (countMap[keyValue] || 0) + 1;
    });

    const resultArray = Object.entries(countMap).map(([k, v]) => ({
        key: JSON.parse(k), count: v
    }));

    return resultArray;
};
